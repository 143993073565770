import request from '@/utils/request'

export function apiGetUserInfo () {
    return request({
        url: '/app/customer/slgetUserInfogo',
        method: 'get'
    })
}


export function apiKickOutDevice (data) {
    return request({
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        url: '/app/customer/slkickOutDeviceke',
        method: 'put',
        data: data
    })
}

export function apiGetUserOrders (data) {
    return request({
        url: '/flash/order/listByUser',
        method: 'get',
        params: data
    })
}

export function apiUpdatepwd (data) {
    return request({
        url: '/client/home/updatePwd',
        method: 'put',
        data: data
    })
}

export function apiGetBindEmailLink (data) {
    return request({
        url: '/app/customer/slgetBindEmailLinkgk',
        method: 'get',
        params: data
    })
}

export function apiBuy (data) {
    return request({
        url: '/h5/user/buy',
        method: 'post',
        data: data
    })
}

export function apiGetRewardInfo () {
    return request({
        url: '/app/customer/slgetRewardInfogo',
        method: 'get'
    })
}

export function apiRemoveAccount (data) {
    return request({
        url: '/app/customer/sldestroyAccountdt',
        method: 'get',
        data: data
    })
}


export function apiGetNewProducts (data) {
    return request({
        url: '/app/customer/slgetProductIdsgs?platform=5&account=' + data.account,
        method: 'get',
    })
}

export function apiIpCheck (data) {
    return request({
        url: '/client/user/ipCheck',
        method: 'get',
        data: data
    })
}
