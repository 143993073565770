import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import(/* webpackChunkName: "index" */ '../views/home/index.vue')
  },
  {
    path: '/cn',
    name: 'cn_index',
    component: () => import(/* webpackChunkName: "cn_index" */ '../views/home/cn_index.vue')
  },
  {
    path: '/tw',
    name: 'tw_index',
    component: () => import(/* webpackChunkName: "tw_index" */ '../views/home/tw_index.vue')
  },
  {
    path: '/ja',
    name: 'ja_index',
    component: () => import(/* webpackChunkName: "ja_index" */ '../views/home/ja_index.vue')
  },
  {
    path: '/ko',
    name: 'ko_index',
    component: () => import(/* webpackChunkName: "ko_index" */ '../views/home/ko_index.vue')
  },
  {
    path: '/ru',
    name: 'ru_index',
    component: () => import(/* webpackChunkName: "ru_index" */ '../views/home/ru_index.vue')
  },
  {
    path: '/es',
    name: 'es_index',
    component: () => import(/* webpackChunkName: "es_index" */ '../views/home/es_index.vue')
  },
  {
    path: '/fr',
    name: 'fr_index',
    component: () => import(/* webpackChunkName: "fr_index" */ '../views/home/fr_index.vue')
  },
  {
    path: '/de',
    name: 'de_index',
    component: () => import(/* webpackChunkName: "de_index" */ '../views/home/de_index.vue')
  },
  
  {
    path: '/faq',
    name: 'faq',
    component: () => import(/* webpackChunkName: "faq" */ '../views/helpCenter/index.vue')
  },
  {
    path: '/cn/faq',
    name: 'cn_faq',
    component: () => import(/* webpackChunkName: "cn_faq" */ '../views/helpCenter/cn_index.vue')
  },
  {
    path: '/tw/faq',
    name: 'tw_faq',
    component: () => import(/* webpackChunkName: "tw_faq" */ '../views/helpCenter/tw_index.vue')
  },
  {
    path: '/ja/faq',
    name: 'ja_faq',
    component: () => import(/* webpackChunkName: "ja_faq" */ '../views/helpCenter/ja_index.vue')
  },
  {
    path: '/ko/faq',
    name: 'ko_faq',
    component: () => import(/* webpackChunkName: "ko_faq" */ '../views/helpCenter/ko_index.vue')
  },
  {
    path: '/ru/faq',
    name: 'ru_faq',
    component: () => import(/* webpackChunkName: "ru_faq" */ '../views/helpCenter/ru_index.vue')
  },
  {
    path: '/es/faq',
    name: 'es_faq',
    component: () => import(/* webpackChunkName: "es_faq" */ '../views/helpCenter/es_index.vue')
  },
  {
    path: '/fr/faq',
    name: 'fr_faq',
    component: () => import(/* webpackChunkName: "fr_faq" */ '../views/helpCenter/fr_index.vue')
  },
  {
    path: '/de/faq',
    name: 'de_faq',
    component: () => import(/* webpackChunkName: "de_faq" */ '../views/helpCenter/de_index.vue')
  },
  {
    path: '/download',
    name: 'download',
    component: () => import(/* webpackChunkName: "download" */ '../views/download/index.vue')
  },
  {
    path: '/cn/download',
    name: 'cn_download',
    component: () => import(/* webpackChunkName: "cn_download" */ '../views/download/cn_index')
  },
  {
    path: '/tw/download',
    name: 'tw_download',
    component: () => import(/* webpackChunkName: "tw_download" */ '../views/download/tw_index')
  },
  {
    path: '/ja/download',
    name: 'ja_download',
    component: () => import(/* webpackChunkName: "ja_download" */ '../views/download/ja_index')
  },
  {
    path: '/ko/download',
    name: 'ko_download',
    component: () => import(/* webpackChunkName: "ko_download" */ '../views/download/ko_index')
  },
  {
    path: '/ru/download',
    name: 'ru_download',
    component: () => import(/* webpackChunkName: "ru_download" */ '../views/download/ru_index')
  },
  {
    path: '/es/download',
    name: 'es_download',
    component: () => import(/* webpackChunkName: "es_download" */ '../views/download/es_index')
  },
  {
    path: '/fr/download',
    name: 'fr_download',
    component: () => import(/* webpackChunkName: "fr_download" */ '../views/download/fr_index')
  },
  {
    path: '/de/download',
    name: 'de_download',
    component: () => import(/* webpackChunkName: "de_download" */ '../views/download/de_index')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../views/register/index_day.vue')
  },
  {
    path: '/cn/register',
    name: 'cn_register',
    component: () => import(/* webpackChunkName: "cn_register" */ '../views/register/cn_index_day.vue')
  },
  {
    path: '/tw/register',
    name: 'tw_register',
    component: () => import(/* webpackChunkName: "tw_register" */ '../views/register/tw_index_day.vue')
  },
  {
    path: '/ja/register',
    name: 'ja_register',
    component: () => import(/* webpackChunkName: "ja_register" */ '../views/register/ja_index_day.vue')
  },
  {
    path: '/ko/register',
    name: 'ko_register',
    component: () => import(/* webpackChunkName: "ko_register" */ '../views/register/ko_index_day.vue')
  },
  {
    path: '/ru/register',
    name: 'ru_register',
    component: () => import(/* webpackChunkName: "ru_register" */ '../views/register/ru_index_day.vue')
  },
  {
    path: '/es/register',
    name: 'es_register',
    component: () => import(/* webpackChunkName: "es_register" */ '../views/register/es_index_day.vue')
  },
  {
    path: '/fr/register',
    name: 'fr_register',
    component: () => import(/* webpackChunkName: "fr_register" */ '../views/register/fr_index_day.vue')
  },
  {
    path: '/de/register',
    name: 'de_register',
    component: () => import(/* webpackChunkName: "de_register" */ '../views/register/de_index_day.vue')
  },
  {
    path: '/myAccount',
    name: 'MyAccount',
    component: () => import(/* webpackChunkName: "myAccount" */ '../views/myAccount/index_day.vue')
  },
  {
    path: '/cn/myAccount',
    name: 'cn_MyAccount',
    component: () => import(/* webpackChunkName: "cn_myAccount" */ '../views/myAccount/cn_index_day.vue')
  },
  {
    path: '/tw/myAccount',
    name: 'tw_MyAccount',
    component: () => import(/* webpackChunkName: "tw_myAccount" */ '../views/myAccount/tw_index_day.vue')
  },

  {
    path: '/ja/myAccount',
    name: 'ja_MyAccount',
    component: () => import(/* webpackChunkName: "ja_myAccount" */ '../views/myAccount/ja_index_day.vue')
  },
  {
    path: '/ko/myAccount',
    name: 'ko_MyAccount',
    component: () => import(/* webpackChunkName: "ko_myAccount" */ '../views/myAccount/ko_index_day.vue')
  },
  {
    path: '/ru/myAccount',
    name: 'ru_MyAccount',
    component: () => import(/* webpackChunkName: "ru_myAccount" */ '../views/myAccount/ru_index_day.vue')
  },
  {
    path: '/es/myAccount',
    name: 'es_MyAccount',
    component: () => import(/* webpackChunkName: "es_myAccount" */ '../views/myAccount/es_index_day.vue')
  },
  {
    path: '/fr/myAccount',
    name: 'fr_MyAccount',
    component: () => import(/* webpackChunkName: "fr_myAccount" */ '../views/myAccount/fr_index_day.vue')
  },
  {
    path: '/de/myAccount',
    name: 'de_MyAccount',
    component: () => import(/* webpackChunkName: "de_myAccount" */ '../views/myAccount/de_index_day.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "orcaLogin" */ '../views/login/index.vue')
  },
  {
    path: '/cn/login',
    name: 'cn_login',
    component: () => import(/* webpackChunkName: "cn_orcaLogin" */ '../views/login/cn_index')
  },
  {
    path: '/tw/login',
    name: 'tw_login',
    component: () => import(/* webpackChunkName: "tw_orcaLogin" */ '../views/login/tw_index')
  },
  {
    path: '/ja/login',
    name: 'ja_login',
    component: () => import(/* webpackChunkName: "ja_orcaLogin" */ '../views/login/ja_index')
  },
  {
    path: '/ko/login',
    name: 'ko_login',
    component: () => import(/* webpackChunkName: "ko_orcaLogin" */ '../views/login/ko_index')
  },
  {
    path: '/ru/login',
    name: 'ru_login',
    component: () => import(/* webpackChunkName: "ru_orcaLogin" */ '../views/login/ru_index')
  },
  {
    path: '/es/login',
    name: 'es_login',
    component: () => import(/* webpackChunkName: "es_orcaLogin" */ '../views/login/es_index')
  },
  {
    path: '/fr/login',
    name: 'fr_login',
    component: () => import(/* webpackChunkName: "fr_orcaLogin" */ '../views/login/fr_index')
  },
  {
    path: '/de/login',
    name: 'de_login',
    component: () => import(/* webpackChunkName: "de_orcaLogin" */ '../views/login/de_index')
  },
  {
    path: '/bindEmail',
    name: 'BindEmail',
    component: () => import(/* webpackChunkName: "bindEmail" */ '../views/bindEmail/index.vue')
  },
  {
    path: '/cn/bindEmail',
    name: 'cn_BindEmail',
    component: () => import(/* webpackChunkName: "cn_bindEmail" */ '../views/bindEmail/cn_index.vue')
  },
  {
    path: '/ja/bindEmail',
    name: 'ja_BindEmail',
    component: () => import(/* webpackChunkName: "ja_bindEmail" */ '../views/bindEmail/ja_index.vue')
  },
  {
    path: '/ko/bindEmail',
    name: 'ko_BindEmail',
    component: () => import(/* webpackChunkName: "ko_bindEmail" */ '../views/bindEmail/ko_index.vue')
  },
  {
    path: '/ru/bindEmail',
    name: 'ru_BindEmail',
    component: () => import(/* webpackChunkName: "ru_bindEmail" */ '../views/bindEmail/ru_index.vue')
  },
  {
    path: '/tw/bindEmail',
    name: 'tw_BindEmail',
    component: () => import(/* webpackChunkName: "tw_bindEmail" */ '../views/bindEmail/tw_index')
  },
  {
    path: '/es/bindEmail',
    name: 'es_BindEmail',
    component: () => import(/* webpackChunkName: "es_bindEmail" */ '../views/bindEmail/es_index')
  },
  {
    path: '/fr/bindEmail',
    name: 'fr_BindEmail',
    component: () => import(/* webpackChunkName: "fr_bindEmail" */ '../views/bindEmail/fr_index')
  },
  {
    path: '/de/bindEmail',
    name: 'de_BindEmail',
    component: () => import(/* webpackChunkName: "de_bindEmail" */ '../views/bindEmail/de_index')
  },

  {
    path: '/forgetPwd',
    name: 'ForgetPwd',
    component: () => import(/* webpackChunkName: "forgetPwd" */ '../views/forgetPwd/index.vue')
  },
  {
    path: '/cn/forgetPwd',
    name: 'cn_ForgetPwd',
    component: () => import(/* webpackChunkName: "cn_forgetPwd" */ '../views/forgetPwd/cn_index.vue')
  },
  {
    path: '/tw/forgetPwd',
    name: 'tw_ForgetPwd',
    component: () => import(/* webpackChunkName: "tw_forgetPwd" */ '../views/forgetPwd/tw_index')
  },
  {
    path: '/ja/forgetPwd',
    name: 'ja_ForgetPwd',
    component: () => import(/* webpackChunkName: "ja_forgetPwd" */ '../views/forgetPwd/ja_index')
  },
  {
    path: '/ko/forgetPwd',
    name: 'ko_ForgetPwd',
    component: () => import(/* webpackChunkName: "ko_forgetPwd" */ '../views/forgetPwd/ko_index')
  },
  {
    path: '/ru/forgetPwd',
    name: 'ru_ForgetPwd',
    component: () => import(/* webpackChunkName: "ru_forgetPwd" */ '../views/forgetPwd/ru_index')
  },
  {
    path: '/es/forgetPwd',
    name: 'es_ForgetPwd',
    component: () => import(/* webpackChunkName: "es_forgetPwd" */ '../views/forgetPwd/es_index')
  },
  {
    path: '/fr/forgetPwd',
    name: 'fr_ForgetPwd',
    component: () => import(/* webpackChunkName: "fr_forgetPwd" */ '../views/forgetPwd/fr_index')
  },
  {
    path: '/de/forgetPwd',
    name: 'de_ForgetPwd',
    component: () => import(/* webpackChunkName: "de_forgetPwd" */ '../views/forgetPwd/de_index')
  },
  {
    path: '/resetPwd',
    name: 'ResetPwd',
    component: () => import(/* webpackChunkName: "resetPwd" */ '../views/resetPwd/index.vue')
  },
  {
    path: '/cn/resetPwd',
    name: 'cn_ResetPwd',
    component: () => import(/* webpackChunkName: "cn_resetPwd" */ '../views/resetPwd/cn_index.vue')
  },
  {
    path: '/tw/resetPwd',
    name: 'tw_ResetPwd',
    component: () => import(/* webpackChunkName: "tw_resetPwd" */ '../views/resetPwd/tw_index')
  },
  {
    path: '/ja/resetPwd',
    name: 'ja_ResetPwd',
    component: () => import(/* webpackChunkName: "ja_resetPwd" */ '../views/resetPwd/ja_index')
  },
  {
    path: '/ko/resetPwd',
    name: 'ko_ResetPwd',
    component: () => import(/* webpackChunkName: "ko_resetPwd" */ '../views/resetPwd/ko_index')
  },
  {
    path: '/ru/resetPwd',
    name: 'ru_ResetPwd',
    component: () => import(/* webpackChunkName: "ru_resetPwd" */ '../views/resetPwd/ru_index')
  },
  {
    path: '/es/resetPwd',
    name: 'es_ResetPwd',
    component: () => import(/* webpackChunkName: "es_resetPwd" */ '../views/resetPwd/es_index')
  },
  {
    path: '/fr/resetPwd',
    name: 'fr_ResetPwd',
    component: () => import(/* webpackChunkName: "fr_resetPwd" */ '../views/resetPwd/fr_index')
  },
  {
    path: '/de/resetPwd',
    name: 'de_ResetPwd',
    component: () => import(/* webpackChunkName: "de_resetPwd" */ '../views/resetPwd/de_index')
  },
  {
    path: '/privacyPolicy',
    name: 'PrivacyPolicy',
    component: () => import(/* webpackChunkName: "privacyPolicy" */ '../views/privacyPolicy/index.vue')
  },
  {
    path: '/cn/privacyPolicy',
    name: 'cn_PrivacyPolicy',
    component: () => import(/* webpackChunkName: "cn_privacyPolicy" */ '../views/privacyPolicy/cn_index.vue')
  },
  {
    path: '/tw/privacyPolicy',
    name: 'tw_PrivacyPolicy',
    component: () => import(/* webpackChunkName: "tw_privacyPolicy" */ '../views/privacyPolicy/tw_index')
  },
  {
    path: '/ja/privacyPolicy',
    name: 'ja_PrivacyPolicy',
    component: () => import(/* webpackChunkName: "ja_privacyPolicy" */ '../views/privacyPolicy/ja_index.vue')
  },
  {
    path: '/ko/privacyPolicy',
    name: 'ko_PrivacyPolicy',
    component: () => import(/* webpackChunkName: "ko_privacyPolicy" */ '../views/privacyPolicy/ko_index.vue')
  },
  {
    path: '/ru/privacyPolicy',
    name: 'ru_PrivacyPolicy',
    component: () => import(/* webpackChunkName: "ru_privacyPolicy" */ '../views/privacyPolicy/ru_index.vue')
  },
  {
    path: '/es/privacyPolicy',
    name: 'es_PrivacyPolicy',
    component: () => import(/* webpackChunkName: "es_privacyPolicy" */ '../views/privacyPolicy/es_index.vue')
  },
  {
    path: '/fr/privacyPolicy',
    name: 'fr_PrivacyPolicy',
    component: () => import(/* webpackChunkName: "fr_privacyPolicy" */ '../views/privacyPolicy/fr_index.vue')
  },
  {
    path: '/de/privacyPolicy',
    name: 'de_PrivacyPolicy',
    component: () => import(/* webpackChunkName: "de_privacyPolicy" */ '../views/privacyPolicy/de_index.vue')
  },
  {
    path: '/terms-of-service',
    name: 'TermsService',
    component: () => import(/* webpackChunkName: "termsService" */ '../views/termsService/index.vue')
  },
  {
    path: '/cn/terms-of-service',
    name: 'cn_TermsService',
    component: () => import(/* webpackChunkName: "cn_termsService" */ '../views/termsService/cn_index.vue')
  },
  {
    path: '/tw/terms-of-service',
    name: 'tw_TermsService',
    component: () => import(/* webpackChunkName: "tw_termsService" */ '../views/termsService/tw_index')
  },
  {
    path: '/ja/terms-of-service',
    name: 'ja_TermsService',
    component: () => import(/* webpackChunkName: "ja_termsService" */ '../views/termsService/ja_index.vue')
  },
  {
    path: '/ko/terms-of-service',
    name: 'ko_TermsService',
    component: () => import(/* webpackChunkName: "ko_termsService" */ '../views/termsService/ko_index.vue')
  },
  {
    path: '/ru/terms-of-service',
    name: 'ru_TermsService',
    component: () => import(/* webpackChunkName: "ru_termsService" */ '../views/termsService/ru_index.vue')
  },
  {
    path: '/es/terms-of-service',
    name: 'es_TermsService',
    component: () => import(/* webpackChunkName: "es_termsService" */ '../views/termsService/es_index.vue')
  },
  {
    path: '/fr/terms-of-service',
    name: 'fr_TermsService',
    component: () => import(/* webpackChunkName: "fr_termsService" */ '../views/termsService/fr_index.vue')
  },
  {
    path: '/de/terms-of-service',
    name: 'de_TermsService',
    component: () => import(/* webpackChunkName: "de_termsService" */ '../views/termsService/de_index.vue')
  },
  {
    path: '/pay-success',
    name: 'paySuccess',
    component: () => import(/* webpackChunkName: "paySuccess" */ '../views/paySuccess/index.vue')
  },
  {
    path: '/cn/pay-success',
    name: 'cn_paySuccess',
    component: () => import(/* webpackChunkName: "cn_paySuccess" */ '../views/paySuccess/cn_index.vue')
  },
  {
    path: '/tw/pay-success',
    name: 'tw_paySuccess',
    component: () => import(/* webpackChunkName: "tw_paySuccess" */ '../views/paySuccess/tw_index')
  },
  {
    path: '/ja/pay-success',
    name: 'ja_paySuccess',
    component: () => import(/* webpackChunkName: "ja_paySuccess" */ '../views/paySuccess/ja_index.vue')
  },
  {
    path: '/ko/pay-success',
    name: 'ko_paySuccess',
    component: () => import(/* webpackChunkName: "ko_paySuccess" */ '../views/paySuccess/ko_index.vue')
  },
  {
    path: '/ru/pay-success',
    name: 'ru_paySuccess',
    component: () => import(/* webpackChunkName: "ru_paySuccess" */ '../views/paySuccess/ru_index.vue')
  },
  {
    path: '/es/pay-success',
    name: 'es_paySuccess',
    component: () => import(/* webpackChunkName: "es_paySuccess" */ '../views/paySuccess/es_index.vue')
  },
  {
    path: '/fr/pay-success',
    name: 'fr_paySuccess',
    component: () => import(/* webpackChunkName: "fr_paySuccess" */ '../views/paySuccess/fr_index.vue')
  },
  {
    path: '/de/pay-success',
    name: 'de_paySuccess',
    component: () => import(/* webpackChunkName: "de_paySuccess" */ '../views/paySuccess/de_index.vue')
  },
  {
    path: '/pay-cancel',
    name: 'payCancel',
    component: () => import(/* webpackChunkName: "payCancel" */ '../views/payCancel/index.vue')
  },
  {
    path: '/cn/pay-cancel',
    name: 'cn_payCancel',
    component: () => import(/* webpackChunkName: "cn_payCancel" */ '../views/payCancel/cn_index.vue')
  },
  {
    path: '/tw/pay-cancel',
    name: 'tw_payCancel',
    component: () => import(/* webpackChunkName: "tw_payCancel" */ '../views/payCancel/tw_index')
  },
  {
    path: '/ja/pay-cancel',
    name: 'ja_payCancel',
    component: () => import(/* webpackChunkName: "ja_payCancel" */ '../views/payCancel/ja_index.vue')
  },
  {
    path: '/ko/pay-cancel',
    name: 'ko_payCancel',
    component: () => import(/* webpackChunkName: "ko_payCancel" */ '../views/payCancel/ko_index.vue')
  },
  {
    path: '/ru/pay-cancel',
    name: 'ru_payCancel',
    component: () => import(/* webpackChunkName: "ru_payCancel" */ '../views/payCancel/ru_index.vue')
  },
  {
    path: '/es/pay-cancel',
    name: 'es_payCancel',
    component: () => import(/* webpackChunkName: "es_payCancel" */ '../views/payCancel/es_index.vue')
  },
  {
    path: '/fr/pay-cancel',
    name: 'fr_payCancel',
    component: () => import(/* webpackChunkName: "fr_payCancel" */ '../views/payCancel/fr_index.vue')
  },
  {
    path: '/de/pay-cancel',
    name: 'de_payCancel',
    component: () => import(/* webpackChunkName: "de_payCancel" */ '../views/payCancel/de_index.vue')
  },
  {
    path: '/pay-result',
    name: 'payResult',
    component: () => import(/* webpackChunkName: "payResult" */ '../views/sge/index.vue')
  },
  {
    path: '/cn/pay-result',
    name: 'cn_payResult',
    component: () => import(/* webpackChunkName: "cn_payResult" */ '../views/sge/cn_index.vue')
  },
  {
    path: '/tw/pay-result',
    name: 'tw_payResult',
    component: () => import(/* webpackChunkName: "tw_payResult" */ '../views/sge/tw_index.vue')
  },
  {
    path: '/ja/pay-result',
    name: 'ja_payResult',
    component: () => import(/* webpackChunkName: "ja_payResult" */ '../views/sge/ja_index.vue')
  },
  {
    path: '/ko/pay-result',
    name: 'ko_payResult',
    component: () => import(/* webpackChunkName: "ko_payResult" */ '../views/sge/ko_index.vue')
  },
  {
    path: '/ru/pay-result',
    name: 'ru_payResult',
    component: () => import(/* webpackChunkName: "ru_payResult" */ '../views/sge/ru_index.vue')
  },
  {
    path: '/es/pay-result',
    name: 'es_payResult',
    component: () => import(/* webpackChunkName: "es_payResult" */ '../views/sge/es_index.vue')
  },
  {
    path: '/fr/pay-result',
    name: 'fr_payResult',
    component: () => import(/* webpackChunkName: "fr_payResult" */ '../views/sge/fr_index.vue')
  },
  {
    path: '/de/pay-result',
    name: 'de_payResult',
    component: () => import(/* webpackChunkName: "de_payResult" */ '../views/sge/de_index.vue')
  },
  {
    path: '/cn/ios-help',
    name: 'iosHelp',
    component: () => import(/* webpackChunkName: "iosHelp" */ '../views/iosHelp/cn_index.vue')
  },
  {
    path: '/cn/ios-doc1',
    name: 'iosDoc1',
    component: () => import(/* webpackChunkName: "iosDoc1" */ '../views/iosHelp/iosHelp1.vue')
  },
  {
    path: '/cn/ios-doc2',
    name: 'iosDoc2',
    component: () => import(/* webpackChunkName: "iosDoc2" */ '../views/iosHelp/iosHelp2.vue')
  },
  {
    path: '/cn/ios-doc3',
    name: 'iosDoc3',
    component: () => import(/* webpackChunkName: "iosDoc3" */ '../views/iosHelp/iosHelp3.vue')
  },
  {
    path: '/cn/ios-doc4',
    name: 'iosDoc4',
    component: () => import(/* webpackChunkName: "iosDoc4" */ '../views/iosHelp/iosHelp4.vue')
  },
  {
    path: '/eula',
    name: 'eula',
    component: () => import(/* webpackChunkName: "eula" */ '../views/eula/index.vue')
  },
  {
    path: '/aboutus',
    name: 'aboutus',
    component: () => import(/* webpackChunkName: "eula" */ '../views/aboutus/index.vue')
  },
  {
    path: '/cn/aboutus',
    name: 'cn_aboutus',
    component: () => import(/* webpackChunkName: "eula" */ '../views/aboutus/cn_index')
  },
  {
    path: '/cn/affiliate-program',
    name: 'cn_affiliate-program',
    component: () => import(/* webpackChunkName: "cn_affiliate-program" */ '../views/proxy/cn_index')
  },
  {
    path: '/affiliate-program',
    name: 'affiliate-program',
    component: () => import(/* webpackChunkName: "affiliate-program" */ '../views/proxy/index')
  },
  {
    path: '/tw/affiliate-program',
    name: 'tw_affiliate-program',
    component: () => import(/* webpackChunkName: "tw_affiliate-program" */ '../views/proxy/tw_index')
  },
  {
    path: '/ja/affiliate-program',
    name: 'ja_affiliate-program',
    component: () => import(/* webpackChunkName: "ja_affiliate-program" */ '../views/proxy/ja_index')
  },
  {
    path: '/ko/affiliate-program',
    name: 'ko_affiliate-program',
    component: () => import(/* webpackChunkName: "ko_affiliate-program" */ '../views/proxy/ko_index')
  },
  {
    path: '/ru/affiliate-program',
    name: 'ru_affiliate-program',
    component: () => import(/* webpackChunkName: "ru_affiliate-program" */ '../views/proxy/ru_index')
  },
  {
    path: '/es/affiliate-program',
    name: 'es_affiliate-program',
    component: () => import(/* webpackChunkName: "es_affiliate-program" */ '../views/proxy/es_index')
  },
  {
    path: '/fr/affiliate-program',
    name: 'fr_affiliate-program',
    component: () => import(/* webpackChunkName: "fr_affiliate-program" */ '../views/proxy/fr_index')
  },
  {
    path: '/de/affiliate-program',
    name: 'de_affiliate-program',
    component: () => import(/* webpackChunkName: "de_affiliate-program" */ '../views/proxy/de_index')
  },
  {
    path: '/cn/registAgent',
    name: 'cn_registAgent',
    component: () => import(/* webpackChunkName: "cn_registAgent" */ '../views/registAgent/cn_index.vue')
  },
  {
    path: '/registAgent',
    name: 'registAgent',
    component: () => import(/* webpackChunkName: "cregistAgent" */ '../views/registAgent/index.vue')
  },
  {
    path: '/tw/registAgent',
    name: 'tw_registAgent',
    component: () => import(/* webpackChunkName: "tw_registAgent" */ '../views/registAgent/tw_index.vue')
  },
  {
    path: '/ja/registAgent',
    name: 'ja_registAgent',
    component: () => import(/* webpackChunkName: "ja_registAgent" */ '../views/registAgent/ja_index.vue')
  },
  {
    path: '/ko/registAgent',
    name: 'ko_registAgent',
    component: () => import(/* webpackChunkName: "ko_registAgent" */ '../views/registAgent/ko_index.vue')
  },
  {
    path: '/ru/registAgent',
    name: 'ru_registAgent',
    component: () => import(/* webpackChunkName: "ru_registAgent" */ '../views/registAgent/ru_index.vue')
  },
  {
    path: '/es/registAgent',
    name: 'es_registAgent',
    component: () => import(/* webpackChunkName: "es_registAgent" */ '../views/registAgent/es_index.vue')
  },
  {
    path: '/fr/registAgent',
    name: 'fr_registAgent',
    component: () => import(/* webpackChunkName: "fr_registAgent" */ '../views/registAgent/fr_index.vue')
  },
  {
    path: '/de/registAgent',
    name: 'de_registAgent',
    component: () => import(/* webpackChunkName: "de_registAgent" */ '../views/registAgent/de_index.vue')
  },
  {
    path: '/cn/myPromotion',
    name: 'cn_myPromotion',
    component: () => import(/* webpackChunkName: "cn_myPromotion" */ '../views/myPromotion/cn_index.vue')
  },
  {
    path: '/myPromotion',
    name: 'myPromotion',
    component: () => import(/* webpackChunkName: "myPromotion" */ '../views/myPromotion/index.vue')
  },
  {
    path: '/tw/myPromotion',
    name: 'tw_myPromotion',
    component: () => import(/* webpackChunkName: "tw_myPromotion" */ '../views/myPromotion/tw_index.vue')
  },
  {
    path: '/ja/myPromotion',
    name: 'ja_myPromotion',
    component: () => import(/* webpackChunkName: "ja_myPromotion" */ '../views/myPromotion/ja_index.vue')
  },
  {
    path: '/ko/myPromotion',
    name: 'ko_myPromotion',
    component: () => import(/* webpackChunkName: "ko_myPromotion" */ '../views/myPromotion/ko_index.vue')
  },
  {
    path: '/ru/myPromotion',
    name: 'ru_myPromotion',
    component: () => import(/* webpackChunkName: "ru_myPromotion" */ '../views/myPromotion/ru_index.vue')
  },
  {
    path: '/es/myPromotion',
    name: 'es_myPromotion',
    component: () => import(/* webpackChunkName: "es_myPromotion" */ '../views/myPromotion/es_index.vue')
  },
  {
    path: '/fr/myPromotion',
    name: 'fr_myPromotion',
    component: () => import(/* webpackChunkName: "fr_myPromotion" */ '../views/myPromotion/fr_index.vue')
  },
  {
    path: '/de/myPromotion',
    name: 'de_myPromotion',
    component: () => import(/* webpackChunkName: "de_myPromotion" */ '../views/myPromotion/de_index.vue')
  },
  {
    path: '/cn/withdrawRecord',
    name: 'cn_withdrawRecord',
    component: () => import(/* webpackChunkName: "cn_withdrawRecord" */ '../views/withdrawRecord/cn_index.vue')
  },
  {
    path: '/withdrawRecord',
    name: 'withdrawRecord',
    component: () => import(/* webpackChunkName: "withdrawRecord" */ '../views/withdrawRecord/index.vue')
  },
  {
    path: '/tw/withdrawRecord',
    name: 'tw_withdrawRecord',
    component: () => import(/* webpackChunkName: "tw_withdrawRecord" */ '../views/withdrawRecord/tw_index.vue')
  },
  {
    path: '/ja/withdrawRecord',
    name: 'ja_withdrawRecord',
    component: () => import(/* webpackChunkName: "ja_withdrawRecord" */ '../views/withdrawRecord/ja_index.vue')
  },
  {
    path: '/ko/withdrawRecord',
    name: 'ko_withdrawRecord',
    component: () => import(/* webpackChunkName: "ko_withdrawRecord" */ '../views/withdrawRecord/ko_index.vue')
  },
  {
    path: '/ru/withdrawRecord',
    name: 'ru_withdrawRecord',
    component: () => import(/* webpackChunkName: "ru_withdrawRecord" */ '../views/withdrawRecord/ru_index.vue')
  },
  {
    path: '/es/withdrawRecord',
    name: 'es_withdrawRecord',
    component: () => import(/* webpackChunkName: "es_withdrawRecord" */ '../views/withdrawRecord/es_index.vue')
  },
  {
    path: '/fr/withdrawRecord',
    name: 'fr_withdrawRecord',
    component: () => import(/* webpackChunkName: "fr_withdrawRecord" */ '../views/withdrawRecord/fr_index.vue')
  },
  {
    path: '/de/withdrawRecord',
    name: 'de_withdrawRecord',
    component: () => import(/* webpackChunkName: "de_withdrawRecord" */ '../views/withdrawRecord/de_index.vue')
  },
  {
    path: '/cooperation',
    name: 'cooperation',
    component: () => import(/* webpackChunkName: "cooperation" */ '../views/advertisement/Page.vue')
  },
  {
    path: '/cn/affiliate-login',
    name: 'cn_affiliate-login',
    component: () => import(/* webpackChunkName: "cn_affiliate-login" */ '../views/affiliateLogin/cn_index.vue')
  },
  {
    path: '/affiliate-login',
    name: 'affiliate-login',
    component: () => import(/* webpackChunkName: "affiliate-login" */ '../views/affiliateLogin/index.vue')
  },
  {
    path: '/tw/affiliate-login',
    name: 'tw_affiliate-login',
    component: () => import(/* webpackChunkName: "tw_affiliate-login" */ '../views/affiliateLogin/tw_index.vue')
  },
  {
    path: '/ja/affiliate-login',
    name: 'ja_affiliate-login',
    component: () => import(/* webpackChunkName: "ja_affiliate-login" */ '../views/affiliateLogin/ja_index.vue')
  },
  {
    path: '/ko/affiliate-login',
    name: 'ko_affiliate-login',
    component: () => import(/* webpackChunkName: "ko_affiliate-login" */ '../views/affiliateLogin/ko_index.vue')
  },
  {
    path: '/ru/affiliate-login',
    name: 'ru_affiliate-login',
    component: () => import(/* webpackChunkName: "ru_affiliate-login" */ '../views/affiliateLogin/ru_index.vue')
  },
  {
    path: '/es/affiliate-login',
    name: 'es_affiliate-login',
    component: () => import(/* webpackChunkName: "es_affiliate-login" */ '../views/affiliateLogin/es_index.vue')
  },
  {
    path: '/fr/affiliate-login',
    name: 'fr_affiliate-login',
    component: () => import(/* webpackChunkName: "fr_affiliate-login" */ '../views/affiliateLogin/fr_index.vue')
  },
  {
    path: '/de/affiliate-login',
    name: 'de_affiliate-login',
    component: () => import(/* webpackChunkName: "de_affiliate-login" */ '../views/affiliateLogin/de_index.vue')
  },
  {
    path: '*',
    name: 'notFound',
    redirect: '/error'
  },
  {
    path: '/error',
    name: 'Error',
    component: () => import(/* webpackChunkName: "notFound" */ '../views/notFound/NotFound.vue')
  }
]


// 防止连续点击多次路由报错
let routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history', // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes: routes
})

export default router


