// zh.js
export default {
    header: {
        home: 'Домашняя страница',
        download: 'скачать',
        helpCenter: 'помощь',
        signIn: 'Авторизоваться',
        myAccount: 'мой счет',
        changePwd: 'изменить пароль',
        signOut: 'выход',
        vip: 'Купить',
        blog: 'блог',
        promotionAgency: 'Агент по продвижению',
        yourIP: 'Ваш IP: ',
        yourStatus: 'Ваш статус: ',
        statusProtected: 'защищенный',
        statusUnprotected: 'незащищенный',
        statusDesc: 'Чтобы скрыть свой IP-адрес, вам нужно получить LightningX VPN и подключиться к одному из серверов.'
    },
    tagsView: {
        refresh: 'обновить',
        close: 'закрытие',
        closeOthers: 'Закрыть другое',
        closeAll: 'Закрыть все'
    },
    settings: {
        title: 'Конфигурация компоновки системы',
        theme: 'цвет темы',
        tagsView: 'включать Tags-View',
        fixedHeader: 'зафиксированный Header',
        sidebarLogo: 'Боковая панель Logo'
    },
    footer: {
        pp: 'политика конфиденциальности',
        ts: 'Условия использования',
        company: 'компания',
        email: 'почта',
        address: 'адрес',
        download: 'скачать',
        helpCenter: 'Центр помощи',
        about: 'О Шаньляне',
        productFaqs: 'Общая проблема',
        ios_help: 'iOS FAQS',
        apk_help: 'Android FAQS',
        mac_help: 'macOS FAQS',
        win_help: 'Windows FAQS',
        cooperation: 'Сотрудничество',
        franchise: 'Я хочу присоединиться',
        guarantee:'30-дневная гарантия возврата денег'
    },
    notFound: {
        content: 'Информация, к которой вы хотите получить доступ, не существует...',
        toHomePage: 'Вернуться на домашнюю страницу'
    },
    cookiesPolicy: {
        desc: 'Этот веб-сайт использует файлы cookie. Чтобы узнать больше, ознакомьтесь с нашей<a href="/ru/privacyPolicy" target="_blank" class="">&nbsp;Политикой конфиденциальности</a><span>.</span>',
        btnAllow: 'принимать',
        btnDenied: 'Отклонить'
    }
}
