// en.js
export default {
  header: {
    home: 'Home',
    download: 'Download',
    helpCenter: 'Help Center',
    signIn: 'Sign In',
    myAccount: 'My Account',
    changePwd: 'Change Password',
    signOut: 'Sign Out',
    vip: 'Price',
    blog:'Blog',
    promotionAgency:'promotion agent',
    yourIP: 'Your IP: ',
    yourStatus: 'Your Status: ',
    statusProtected: 'Protected',
    statusUnprotected: 'Unprotected',
    statusDesc: 'To hide your IP address, you need to get the LightningX VPN and connect to one of the servers.'
  },
  tagsView: {
    refresh: 'Refresh',
    close: 'Close',
    closeOthers: 'Close Others',
    closeAll: 'Close All'
  },
  settings: {
    title: 'Page style setting',
    theme: 'Theme Color',
    tagsView: 'Open Tags-View',
    fixedHeader: 'Fixed Header',
    sidebarLogo: 'Sidebar Logo'
  },
  footer: {
    pp: 'Privacy Policy',
    ts: 'Terms of Service',
    company: 'Company Name',
    email: 'Email',
    address: 'Address',
    download: 'Download',
    helpCenter: 'Help Center',
    about: 'About Us',
    productFaqs: 'FAQS',
    ios_help:'iOS FAQS',
    apk_help:'Android FAQS',
    mac_help:'macOS FAQS',
    win_help:'Windows FAQS',
    cooperation:'Cooperation',
    franchise:'Become an Affiliate',
    guarantee:'30-day money-back guarantee'
  },
  notFound:{
    content:'The information you want to access does not exist...',
    toHomePage:'Back to homepage'
  },
  cookiesPolicy: {
    desc: 'This website uses cookies to improve the user experience. To learn more, please check our<a href="/privacyPolicy" target="_blank" class="">&nbsp;Privacy Policy</a><span>.</span>',
    btnAllow: 'Accept',
    btnDenied: 'Decline'
  }

}
