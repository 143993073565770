// zh.js
export default {
    header: {
        home: '홈페이지',
        download: '다운로드',
        helpCenter: '도움말',
        signIn: '로그인',
        myAccount: '내 계정',
        changePwd: '비밀번호 변경',
        signOut: '로그아웃',
        vip: '구매',
        blog: '블로그',
        promotionAgency: '프로모션 에이전트',
        yourIP: '귀하의 IP: ',
        yourStatus: '귀하의 상태: ',
        statusProtected: '보호됨',
        statusUnprotected: '비보호',
        statusDesc: 'IP 주소를 숨기려면 LightningX VPN을 가져 와서 서버 중 하나에 연결해야합니다.'
    },
    tagsView: {
        refresh: '새로 고침',
        close: '닫기',
        closeOthers: '다른 사람 닫기',
        closeAll: '모두 닫기'
    },
    settings: {
        title: '시스템 레이아웃 구성',
        theme: '테마 색상',
        tagsView: '태그 보기 켜기',
        fixedHeader: '고정 헤더',
        sidebarLogo: '사이드바 로고'
    },
    footer: {
        pp: '개인정보 보호정책',
        ts: '서비스 약관',
        company: '회사',
        email: '메일',
        address: '주소',
        download: '다운로드',
        helpCenter: '도움말 센터',
        about: '회사 소개',
        productFaqs: '제품 FAQ',
        ios_help: 'iOS FAQS',
        apk_help: 'Android FAQS',
        mac_help: 'macOS FAQS',
        win_help: 'Windows FAQS',
        cooperation: '협력',
        franchise: '가입하기',
        guarantee:'30 일 환불 보장'
    },
    notFound: {
        content: '접근하려는 정보가 존재하지 않습니다...',
        toHomePage: '홈 페이지로 돌아가기'
    },
    cookiesPolicy: {
        desc: '이 웹사이트는 쿠키를 사용합니다. 자세한 내용은<a href="/ko/privacyPolicy" target="_blank" class="">&nbsp;개인 정보 보호 정책</a><span>을 확인하십시오.</span>',
        btnAllow: '동의하기',
        btnDenied: '감소'
    }
}
