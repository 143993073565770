// tw.js
export default {
    header: {
        home: '首頁',
        download: '下載',
        helpCenter: '幫助',
        signIn: '登入',
        myAccount: '我的帳戶',
        changePwd: '修改密碼',
        signOut: '登出',
        vip: '購買',
        blog: '部落格',
        promotionAgency: '推廣代理',
        yourIP: '您的 IP 地址: ',
        yourStatus: '您當前的狀態: ',
        statusProtected: '保護中',
        statusUnprotected: '未保護',
        statusDesc: '要隱藏您的 IP 地址，您需要下載闪连VPN並連接至一台服務器。'
    },
    tagsView: {
        refresh: '刷新',
        close: '關閉',
        closeOthers: '關閉其它',
        closeAll: '關閉所有'
    },
    settings: {
        title: '系统布局配置',
        theme: '主题色',
        tagsView: '开启 Tags-View',
        fixedHeader: '固定 Header',
        sidebarLogo: '侧边栏 Logo'
    },
    footer: {
        pp: '隱私權政策',
        ts: '服務條款',
        company: '公司',
        email: '郵件',
        address: '地址',
        download: '下載',
        helpCenter: '幫助中心',
        about: '關於閃連',
        productFaqs: '常見問題',
        ios_help: 'iOS 幫助',
        apk_help: 'Android 幫助',
        mac_help: 'macOS 幫助',
        win_help: 'Windows 幫助',
        cooperation: '合作',
        franchise: '我要加盟',
        guarantee:'30天無理由退款'
    },
    notFound: {
        content: '您要存取的資訊不存在...',
        toHomePage: '回到首頁'
    },
    cookiesPolicy: {
        desc: '為改善用戶體驗，此網站使用 Cookie。要了解更多關於 Cookie 政策的信息或拒絕使用 Cookie，請查看我們的<a href="/tw/privacyPolicy" target="_blank" class="">隱私政策</a><span>。</span>',
        btnAllow: '同意',
        btnDenied: '拒絕'
    }

}
