// index.js
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Cookies from 'js-cookie'
import enLocale from './en'
import zhLocale from './zh'
import twLocale from './tw'
import jaLocale from './ja'
import koLocale from './ko'
import ruLocale from './ru'
import esLocale from './es'
import frLocale from './fr'
import deLocale from './de'

Vue.use(VueI18n)

const messages = {
  en: {
    ...enLocale
  },
  zh: {
    ...zhLocale
  },
  tw: {
    ...twLocale
  },
  ja: {
    ...jaLocale
  },
  ko: {
    ...koLocale
  },
  ru: {
    ...ruLocale
  },
  es: {
    ...esLocale
  },
  fr: {
    ...frLocale
  },
  de: {
    ...deLocale
  }

}

const i18n = new VueI18n({
  // 设置语言 选项 en | zh
  locale: Cookies.get('language') || 'en',
  // 设置文本内容
  messages
})

export default i18n
