// fr.js
export default {
    header: {
        home: 'Accueil',
        download: 'Télécharger',
        helpCenter: "Centre d'aide",
        signIn: 'Se connecter',
        myAccount: 'Mon compte',
        changePwd: 'Changer le mot de passe',
        signOut: 'Déconnexion',
        vip: 'Prix',
        blog: 'Blog',
        promotionAgency: 'Agent de promotion',
        yourIP: 'Votre IP : ',
        yourStatus: 'Votre statut : ',
        statusProtected: 'Protégé',
        statusUnprotected: 'Non protégé',
        statusDesc: "Pour masquer votre adresse IP, vous devez obtenir LightningX VPN et vous connecter à l'un des serveurs."
    },
    tagsView: {
        refresh: 'Actualiser',
        close: 'Fermer',
        closeOthers: 'Fermer les autres',
        closeAll: 'Fermer tout'
    },
    settings: {
        title: 'Réglage du style de page',
        theme: 'Couleur du thème',
        tagsView: 'Ouvrir la vue des tags',
        fixedHeader: 'En-tête fixe',
        sidebarLogo: 'Logo de la barre latérale'
    },
    footer: {
        pp: 'Politique de confidentialité',
        ts: 'Conditions de service',
        company: "Nom de l'entreprise",
        email: 'Email',
        address: 'Adresse',
        download: 'Télécharger',
        helpCenter: "Centre d'aide",
        about: 'À propos de nous',
        productFaqs: 'FAQ',
        ios_help: 'FAQ iOS',
        apk_help: 'FAQ Android',
        mac_help: 'FAQ macOS',
        win_help: 'FAQ Windows',
        cooperation: 'Coopération',
        franchise: 'Devenir affilié',
        guarantee: 'Garantie de remboursement de 30 jours'
    },
    notFound: {
        content: "Les informations que vous souhaitez consulter n'existent pas...",
        toHomePage: "Retour à la page d'accueil"
    },
    cookiesPolicy: {
        desc: `Ce site web utilise des cookies pour améliorer l'expérience utilisateur. Pour en savoir plus, veuillez consulter notre <a href="/privacyPolicy" target="_blank" class="">&nbsp;Politique de confidentialité</a><span>.</span>`,
        btnAllow: 'Accepter',
        btnDenied: 'Refuser'
    }

}
