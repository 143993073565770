// zh.js
export default {
  header: {
    home: '主页',
    download: '下载',
    helpCenter: '帮助',
    signIn: '登录',
    myAccount: '我的账户',
    changePwd: '修改密码',
    signOut: '退出登录',
    vip: '购买',
    blog:'博客',
    promotionAgency:'推广代理',
    yourIP: '您的 IP 地址: ',
    yourStatus: '您当前的状态: ',
    statusProtected: '保护中',
    statusUnprotected: '未保护',
    statusDesc: '要隐藏您的 IP 地址，您需要下载闪连VPN并连接至一台服务器。'
  },
  tagsView: {
    refresh: '刷新',
    close: '关闭',
    closeOthers: '关闭其它',
    closeAll: '关闭所有'
  },
  settings: {
    title: '系统布局配置',
    theme: '主题色',
    tagsView: '开启 Tags-View',
    fixedHeader: '固定 Header',
    sidebarLogo: '侧边栏 Logo'
  },
  footer: {
    pp: '隐私政策',
    ts: '服务条款',
    company: '公司',
    email: '邮件',
    address: '地址',
    download: '下载',
    helpCenter: '帮助中心',
    about: '关于闪连',
    productFaqs: '常见问题',
    ios_help:'iOS 帮助',
    apk_help:'Android 帮助',
    mac_help:'macOS 帮助',
    win_help:'Windows 帮助',
    cooperation:'合作',
    franchise:'我要加盟',
    guarantee:'30天无理由退款'
  },
  notFound:{
    content:'您要访问的信息不存在...',
    toHomePage:'回到首页'
  },
  cookiesPolicy: {
    desc: '为改善用户体验，此网站使用 Cookie。要了解更多关于 Cookie 政策的信息或拒绝使用 Cookie，请查看我们的 <a href="/cn/privacyPolicy" target="_blank" class="">隐私政策</a><span>。</span>',
    btnAllow: '同意',
    btnDenied: '拒绝'
  }
}
