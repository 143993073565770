<template>
  <div>
    <div class="ipinfo">
      <span>{{ $t("header.yourIP") }}</span>
      <span v-if="ipInfo.length > 15 && windowWidth < 650"> {{ipInfo.substr(0,9)}}...</span>
      <span v-else> {{ipInfo}}</span>
      <span style="margin-left: 10px">{{ $t("header.yourStatus") }}</span>
      <span v-if="status" style="color: green;margin-left: 2px"> {{ $t("header.statusProtected") }}</span>
      <span class="statusUnprotected" v-else style="color: red;cursor: pointer;position: relative;margin-left: 2px" @click="getLightningXVPN">
        {{ $t("header.statusUnprotected") }}
        <span class="header-status-tooltip-show show-tooltip">
            {{ $t("header.statusDesc") }}
        </span>
      </span>
    </div>
    <div class="myHeader">
      <div class="myHeader-content">
        <a href="/" class="logo">
          <img src="../../assets/img/shanlian_logo_zh.png" alt="LightningX VPN|闪连VPN-logo" width="183" height="48"
            v-if="this.$i18n.locale == 'zh'" />
          <img class="logoImgEn" alt="LightningX VPN|闪连VPN-logo" src="../../assets/img/shanlian_logo_en.png" width="200"
            height="40" v-else />
        </a>
        <!-- pc -->
        <div class="myHeader-menu" @click="menuClick">
          <router-link :to="homeLink" id="home" :class="currentNavStyle === 'home' ? 'fontStyle' : ''">{{
            $t("header.home")
          }}</router-link>
          <router-link :to="downloadLink" id="download" :class="currentNavStyle === 'download' ? 'fontStyle' : ''">{{
            $t("header.download")
          }}</router-link>
          <a :href="blogLink" id="blog" v-if="showBlog">{{ $t("header.blog") }}</a>
          <router-link :to="registerLink" id="register" :class="currentNavStyle === 'register' ? 'fontStyle' : ''">{{
            $t("header.vip")
          }}</router-link>
          <router-link :to="faqLink" id="faq" :class="currentNavStyle === 'faq' ? 'fontStyle' : ''">{{
            $t("header.helpCenter")
          }}</router-link>
          <router-link :to="loginLink" v-if="!listenUserState" id="login"
            :class="currentNavStyle === 'login' ? 'fontStyle' : ''">{{
              $t("header.signIn")
            }}</router-link>
          <a @click.stop="showMyAccount" class="myHeader-myAccount"
            :class="(currentNavStyle === 'myAccount' || currentNavStyle === 'myPromotion') ? 'fontStyle' : ''" v-else>{{
              $t("header.myAccount")
            }}<a-icon type="menu" style="margin-left: 5px" />
            <div class="myHeader-myAccount-menu" v-if="isShowMyAccount" @click="menuClick">
              <router-link :to="myAccountLink" class="lang" id="myAccount">{{
                $t("header.myAccount")
              }}</router-link>
              <router-link :to="myPromotionLink" class="lang" v-if="isProxy" id="myPromotion">
                {{ $t("header.promotionAgency") }}</router-link>
              <a class="lang" id="signout">{{ $t("header.signOut") }}</a>
            </div>
          </a>

          <a @click.stop="showLang">{{ text }}<a-icon type="down" style="margin-left: 5px" /></a>
          <div class="myHeader-dropdown-menu" v-if="isShowLang">
            <a class="lang" @click="changeLang(item.lang, item.text)" v-for="(item, index) in langData" :key="index">{{
              item.text }}</a>
          </div>
        </div>
        <div class="myHeader-menu-mb">
          <div style="cursor: pointer" @click="showSubLangMenu">
            {{ text }}
            <img src="../../assets/img/home/dropdown.svg" alt="" width="14" height="14" />
          </div>
          <div class="myHeader-menu-submenus" @click="showSubMenu">...</div>
        </div>
      </div>
      <div>

      </div>
    </div>
    <!-- mb -->
    <div class="myHeader-drawer" v-if="isShowSubMenu" @click="menuClick">
      <div class="myHeader-drawer-mb-submenus show">
        <router-link :to="homeLink" id="home">{{ $t("header.home") }}</router-link>
        <router-link :to="downloadLink" id="download">{{ $t("header.download") }}</router-link>
        <a :href="blogLink" id="blog" v-if="showBlog">{{ $t("header.blog") }}</a>
        <router-link :to="registerLink" id="register">{{ $t("header.vip") }}</router-link>
        <router-link :to="faqLink" id="faq">{{ $t("header.helpCenter") }}</router-link>
        <router-link :to="loginLink" id="login" v-if="!listenUserState">{{ $t("header.signIn") }}</router-link>
        <div v-else>
          <router-link :to="myAccountLink" class="lang">{{ $t("header.myAccount") }}</router-link>
          <router-link :to="myPromotionLink" class="lang" v-if="isProxy">
            {{ $t("header.promotionAgency") }}</router-link>
          <a href="" class="lang" id="signout">{{ $t("header.signOut") }}</a>
        </div>

      </div>
    </div>
    <div class="myHeader-drawer" v-if="isShowSubLangMenu" @click="menuClick">
      <div class="myHeader-drawer-mb-submenus show">
        <a class="lang" @click="changeLang(item.lang, item.text)" v-for="(item, index) in langData" :key="index">{{
          item.text }}</a>
      </div>
    </div>


  </div>
</template>

<script>
import { getToken, setToken, removeToken } from "@/utils/auth";
import { getAffiliateInfoApi } from "../../apis/proxy";
import { apiIpCheck } from "../../apis/account";
import i18n from "@/lang";
import Cookies from "js-cookie";
export default {
  name: "Header",
  metaInfo() {
    let baseUrl = 'https://lightningxvpn.com/';
    return {
      htmlAttrs: {
        lang: this.htmlLang
      },
      link: this.generateLinks(baseUrl, this.currentPage, this.currentLang)
    };
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      htmlLang: 'zh',
      currentPage: '', // 默认页面
      currentNavStyle: "home",
      homeLink: "/",
      downloadLink: "/download",
      blogLink: "",
      registerLink: "/register",
      faqLink: "/faq",
      loginLink: "/login",
      myAccountLink: "/myAccount",
      myPromotionLink: "/myPromotion",
      currentLang: "en",
      currentNav: "",
      isShowMyAccount: false,
      isShowLang: false,
      isShowSubMenu: false,
      isShowSubLangMenu: false,
      current: [],
      menuKeys: "home,download,faq,myAccount,signOut",
      text: "English",
      account: "My Account",
      logoSrc: "../../assets/img/orca_logo.png",
      userStatus: false,
      browserLanguage: "",
      isProxy: false,
      langData: [
        { text: 'English', lang: 'en' },
        { text: '简体中文', lang: 'zh' },
        { text: '繁体中文', lang: 'tw' },
        { text: '日本語', lang: 'ja' },
        { text: '한국어', lang: 'ko' },
        { text: 'русский', lang: 'ru' },
        { text: 'Español', lang: 'es' },
        { text: 'Français', lang: 'fr' },
        { text: 'Deutsch', lang: 'de' },
      ],
      ipInfo: '-',
      status: false,
      showBlog: window.location.href.indexOf('lightningxvpn') !== -1
    };
  },
  computed: {
    haveTk: {
      get() {
        return getToken() !== undefined && getToken() !== "";
      },
      set() { },
    },
    listenUserState: {
      get() {
        return this.$store.getters.token;
      },
      set() { },
    },
  },
  watch: {
    // $route: "menuActive",
    '$route'(to, from) {
      // 根据当前路由信息更新导航栏样式
      this.changeNavStyle(to.path);
    },
    '$route.path'(newPath) {
      // 根据路由路径更新 currentPage 的值
      if (newPath.includes('register')) {
        this.currentPage = 'register';
      } else if (newPath.includes('download')) {
        this.currentPage = 'download';
      } else if (newPath.includes('faq')) {
        this.currentPage = 'faq';
      } else if (newPath.includes('login')) {
        this.currentPage = 'login';
      } else if (newPath.includes('faq')) {
        this.currentPage = 'faq';
      } else if (newPath.includes('affiliate-program')) {
        this.currentPage = 'affiliate-program';
      } else if (newPath.includes('privacyPolicy')) {
        this.currentPage = 'privacyPolicy';
      } else if (newPath.includes('terms-of-service')) {
        this.currentPage = 'terms-of-service';
      } else if (newPath.includes('error')) {
        this.currentPage = 'error';
      }else {
        this.currentPage = ''; // 默认值或处理其他情况
      }
      // 更新 meta 信息
      this.$meta().refresh();
    },
    'currentLang'() {
      // 更新 meta 信息
      this.$meta().refresh();
    }
  },
  updated() {
    this.isShowProxy();
    this.changeNavStyle(window.location.pathname);
  },
  created() {
    this.langListen();
    try {
      if (this.listenUserState) {
        getAffiliateInfoApi()
          .then((res1) => {
            if (res1.data === "normal") {
              this.isProxy = false;
            } else {
              this.isProxy = true;
            }
          })
          .catch();
      } else {

      }
    } catch (e) {
      console.log("error:", e);
    }
    apiIpCheck().then((res) => {
        if (res.data) {
          this.ipInfo = res.data.ip
          this.status = res.data.protect
        }
    })
    // this.isShowProxy();
  },
  mounted() {
    window.addEventListener('click', this.handleClick);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.handleClick);
  },
  methods: {
    getLightningXVPN() {
      let langPath = i18n.locale;
      if ('en' === langPath) {
        langPath = ''
      } else if  ('zh' === langPath) {
        langPath = '/cn'
      } else {
        langPath = '/' + langPath
      }
      this.$router.push(langPath + "/register");
    },
    // 在一个单独的文件中定义一个函数，用于生成链接对象数组
    generateLinks(baseUrl, currentPage, currentLang) {
      let links = [
        { lang: 'en', path: '' },
        { lang: 'zh-Hans', path: 'cn/' },
        { lang: 'zh-Hant', path: 'tw/' },
        { lang: 'ja', path: 'ja/' },
        { lang: 'ko', path: 'ko/' },
        { lang: 'ru', path: 'ru/' },
        { lang: 'es', path: 'es/' },
        { lang: 'fr', path: 'fr/' },
        { lang: 'de', path: 'de/' },
      ];
      // 映射 currentLang 到标准化语言代码
      const langMapping = {
        'zh': 'zh-Hans',
        'tw': 'zh-Hant'
      };
      // 获取标准化后的语言代码
      let standardizedLang = langMapping[currentLang] || currentLang;
      let linkArray = links.map(item => {
        return {
          rel: 'alternate',
          hreflang: item.lang,
          href: baseUrl + item.path + currentPage
        };
      });
      // 找到当前语言对应的路径前缀
      let currentPathPrefix = links.find(item => item.lang === standardizedLang)?.path || '';
      // 添加 canonical 和 x-default 链接
      linkArray.unshift(
        { rel: 'canonical', href: baseUrl + currentPathPrefix + currentPage },
        { rel: 'alternate', hreflang: 'x-default', href: baseUrl + currentPage }
      );
      return linkArray;
    },
    isShowProxy() {
      const status = localStorage.getItem("status");
      if (status !== null && status !== "normal") {
        this.isProxy = true;
      } else {
        this.isProxy = false;
      }
    },
    handleClick(event) {
      const clickedElement = document.elementFromPoint(event.clientX, event.clientY);
      if (clickedElement === event.currentTarget) {
      } else {
        this.isShowLang = false;
        this.isShowMyAccount = false;
      }
    },
    handleGlobalClick() {
      // 处理点击事件的逻辑
      this.isShowSubLangMenu = false;
      this.isShowSubMenu = false;
    },
    showMyAccount() {
      this.isShowMyAccount = !this.isShowMyAccount;
      if (this.isShowMyAccount) {
        this.isShowLang = false;
      }
    },
    showLang() {
      this.isShowLang = !this.isShowLang;
      if (this.isShowLang) {
        this.isShowMyAccount = false;
      }
    },
    showSubMenu() {
      this.isShowSubMenu = !this.isShowSubMenu;
      if (this.isShowSubMenu) {
        this.isShowSubLangMenu = false;
      }
    },
    showSubLangMenu() {
      this.isShowSubLangMenu = !this.isShowSubLangMenu;
      if (this.isShowSubLangMenu) {
        this.isShowSubMenu = false;
      }
    },
    langListen() {
      let path = location.pathname;
      let search = location.search;
      // ad 页面不参与
      if (path.indexOf("ad") != -1 && path.indexOf("download") == -1) {
        return;
      }
      //eula不参与
      if (path.indexOf("eula") != -1) {
        return;
      }
      if (path.indexOf("robots") != -1) {
        return;
      }
      if (path.indexOf("sitemap") != -1) {
        return;
      }
      //blog 特殊处理
      if (path.indexOf("blog") != -1) {
        return;
      }
      //blog 特殊处理
      if (path.indexOf("cooperation") != -1) {
        return;
      }
      // if (path.indexOf('blog') != -1 && path.indexOf('article') === -1) {
      //     this.changeLang('zh', '简体中文');
      //     return;
      // }
      //跳转参数带上指定语言 优先级最高
      if (search.indexOf("lang") !== -1) {
        if (search.indexOf("zh-CN") !== -1) {
          this.changeLang("zh", "简体中文");
        } else if (search.indexOf("zh-Hant-HK") !== -1) {
          this.changeLang("tw", "繁體中文");
        } else if (search.indexOf("ja") !== -1) {
          this.changeLang("ja", "日本語");
        } else if (search.indexOf("ko") !== -1) {
          this.changeLang("ko", "한국어");
        } else if (search.indexOf("ru") !== -1) {
          this.changeLang("ru", "русский");
        } else if (search.indexOf("es") !== -1) {
          this.changeLang("es", "españa");
        }  else if (search.indexOf("fr") !== -1) {
          this.changeLang("fr", "Français");
        }  else if (search.indexOf("de") !== -1) {
          this.changeLang("de", "Deutsch");
        } else {
          this.changeLang("en", "English");
        }
        return;
      }
      //url路径上是否携带非英文的语言路径参数
      if (path.startsWith("/cn")) {
        this.changeLang("zh", "简体中文");
        return;
      }
      if (path.startsWith("/tw")) {
        this.changeLang("tw", "繁體中文");
        return;
      }
      if (path.startsWith("/ja")) {
        this.changeLang("ja", "日本語");
        return;
      }
      if (path.startsWith("/ko")) {
        this.changeLang("ko", "한국어");
        return;
      }
      if (path.startsWith("/ru")) {
        this.changeLang("ru", "русский");
        return;
      }
      if (path.startsWith("/es")) {
        this.changeLang("es", "España");
        return;
      }
      if (path.startsWith("/fr")) {
        this.changeLang("fr", "Français");
        return;
      }
      if (path.startsWith("/de")) {
        this.changeLang("de", "Deutsch");
        return;
      }
      //未手动设置过语言 初次进入，根据浏览器语言决定
      let cookLang = Cookies.get("language");
      if (!cookLang) {
        if ("zh-CN" === navigator.language) {
          this.changeLang("zh", "简体中文");
        } else if (
          "zh-Hant" === navigator.language ||
          "zh-TW" === navigator.language
        ) {
          this.changeLang("tw", "繁體中文");
        } else if (
          "ja" === navigator.language.toLowerCase() ||
          "ja-" === navigator.language.toLowerCase()
        ) {
          this.changeLang("ja", "日本語");
        } else if (
          "ko" === navigator.language.toLowerCase() ||
          "ko-" === navigator.language.toLowerCase()
        ) {
          this.changeLang("ko", "한국어");
        } else if (
          "ru" === navigator.language.toLowerCase() ||
          "ru-" === navigator.language.toLowerCase()
        ) {
          this.changeLang("es", "españa");
        } else if (
          "es" === navigator.language.toLowerCase() ||
          "es-" === navigator.language.toLowerCase()
        ) {
          this.changeLang("ja", "日本語");
        } else if (
          "fr" === navigator.language.toLowerCase() ||
          "fr-" === navigator.language.toLowerCase()
        ) {
          this.changeLang("fr", "Français");
        } else if (
          "de" === navigator.language.toLowerCase() ||
          "de-" === navigator.language.toLowerCase()
        ) {
          this.changeLang("de", "Deutsch");
        } else {
          this.changeLang("en", "English");
        }
        return;
      } else {
        //设置过cookies
        if ("zh" == cookLang) {
          this.changeLang("zh", "简体中文");
        } else if ("tw" === cookLang) {
          this.changeLang("tw", "繁體中文");
        } else if ("ja" === cookLang) {
          this.changeLang("ja", "日本語");
        } else if ("ko" === cookLang) {
          this.changeLang("ko", "한국어");
        } else if ("ru" === cookLang) {
          this.changeLang("ru", "русский");
        } else if ("es" === cookLang) {
          this.changeLang("es", "españa");
        } else if ("fr" === cookLang) {
          this.changeLang("fr", "Français");
        } else if ("de" === cookLang) {
          this.changeLang("de", "Deutsch");
        } else {
          this.changeLang("en", "English");
        }
        return;
      }
    },
    menuActive() {
      let path = location.pathname;
      let pathKey = path
        .replaceAll("/", "")
        .replace("cn", "")
        .replace("tw", "")
        .replace("ja", "")
        .replace("ko", "")
        .replace("ru", "")
        .replace("es", "")
        .replace("fr", "")
        .replace("de", "");
      if (pathKey && pathKey.indexOf(this.menuKeys !== -1)) {
        this.current = [pathKey];
      } else {
        this.current = [];
      }
      this.changeCnonical(pathKey);
      //gtag 记录
      gtag("event", "page_view");
      //下载页面记录
      if ("download" === pathKey) {
        gtag("event", "view_download_page");
      }
      if ("register" === pathKey) {
        gtag("event", "view_register_page");
      }
    },
    changeCnonical(path) {
      // 选中页面中的第一个 <link rel="canonical"> 元素
      var canonicalLink = document.querySelector('link[rel="canonical"]');
      // 检查是否找到了元素
      if (canonicalLink) {
        canonicalLink.setAttribute("href", "https://lightningxvpn.com/" + path);
      }
    },
    changeAlternate(lang) {
      // 获取所有 <link rel="alternate"> 标签
      const alternateLinks = document.querySelectorAll('link[rel="alternate"]');
      // 检查是否存在至少两个这样的标签
      if (alternateLinks.length >= 2) {
        // 修改第二个标签的 hreflang 属性
        if ("en" === lang) {
          alternateLinks[1].setAttribute("href", "https://lightningxvpn.com/");
        } else {
          if ("zh" === lang) {
            alternateLinks[1].setAttribute(
              "href",
              "https://lightningxvpn.com/cn/"
            );
          } else {
            alternateLinks[1].setAttribute(
              "href",
              "https://lightningxvpn.com/" + lang + "/"
            );
          }
        }
        let hreflang = "en";
        if ("zh" === lang) {
          hreflang = "zh-CN";
        } else if ("tw" === lang) {
          hreflang = "zh-TW";
        } else if ("ja" === lang) {
          hreflang = "ja";
        } else if ("ko" === lang) {
          hreflang = "ko";
        } else if ("ru" === lang) {
          hreflang = "ru";
        } else if ("es" === lang) {
          hreflang = "es";
        } else if ("fr" === lang) {
          hreflang = "fr";
        }  else if ("de" === lang) {
          hreflang = "de";
        }  else {
          hreflang = "en";
        }
        alternateLinks[1].setAttribute("hreflang", hreflang);
      }
    },
    online() {
      if (getToken() === undefined || getToken() === "") {
        return false;
      } else {
        return true;
      }
    },
    menuClick(e) {
      const key = e.target.getAttribute("id");
      this.currentNavStyle = key;
      let path = key;
      this.handleGlobalClick();
      if (path == null) {
        return;
      }
      if (path.indexOf("setting") !== -1) {
        return;
      }
      if (path == "home") {
        if (this.$i18n.locale === "zh") {
          this.$router.push("/cn/");
        } else if (this.$i18n.locale === "tw") {
          this.$router.push("/tw/");
        } else if (this.$i18n.locale === "ja") {
          this.$router.push("/ja/");
        } else if (this.$i18n.locale === "ko") {
          this.$router.push("/ko/");
        } else if (this.$i18n.locale === "ru") {
          this.$router.push("/ru/");
        } else if (this.$i18n.locale === "es") {
          this.$router.push("/es/");
        }else if (this.$i18n.locale === "fr") {
          this.$router.push("/fr/");
        } else if (this.$i18n.locale === "de") {
          this.$router.push("/de/");
        }  else {
          this.$router.push("/");
        }
        return;
      }
      //blog 特殊处理
      if (path == "blog") {
        if (this.$i18n.locale === "zh") {
          window.location.href = "https://lightningxvpn.com/blog/cn/";
        } else {
          window.location.href = "https://lightningxvpn.com/blog/en/";
        }
        // this.$router.push('/blog')
        return;
      }
      if (path === "signout") {
        this.$store
          .dispatch("LogOut")
          .then(() => {
            if (this.$i18n.locale === "zh") {
              this.$router.push("/cn/login");
            } else if (this.$i18n.locale === "tw") {
              this.$router.push("/tw/login");
            } else if (this.$i18n.locale === "ja") {
              this.$router.push("/ja/login");
            } else if (this.$i18n.locale === "ko") {
              this.$router.push("/ko/login");
            } else if (this.$i18n.locale === "ru") {
              this.$router.push("/ru/login");
            } else if (this.$i18n.locale === "es") {
              this.$router.push("/es/login");
            } else if (this.$i18n.locale === "fr") {
              this.$router.push("/fr/login");
            } else if (this.$i18n.locale === "de") {
              this.$router.push("/de/login");
            } else {
              this.$router.push("/login");
            }
            this.current = ["login"];
            //合作代理
            localStorage.removeItem("status");
            this.isProxy = false;
          })
          .catch(() => { });
        return;
      }
      //购买 登陆和非登陆路由
      if (path == "register") {
        //登陆状态
        if (this.listenUserState) {
          if (this.$i18n.locale === "zh") {
            this.$router.push("/cn/myAccount?renew=3");
          } else if (this.$i18n.locale === "tw") {
            this.$router.push("/tw/myAccount?renew=3");
          } else if (this.$i18n.locale === "ja") {
            this.$router.push("/ja/myAccount?renew=3");
          } else if (this.$i18n.locale === "ko") {
            this.$router.push("/ko/myAccount?renew=3");
          } else if (this.$i18n.locale === "ru") {
            this.$router.push("/ru/myAccount?renew=3");
          } else if (this.$i18n.locale === "es") {
            this.$router.push("/es/myAccount?renew=3");
          }else if (this.$i18n.locale === "fr") {
            this.$router.push("/fr/myAccount?renew=3");
          }else if (this.$i18n.locale === "de") {
            this.$router.push("/de/myAccount?renew=3");
          } else {
            this.$router.push("/myAccount?renew=3");
          }
          this.current = ["myAccount"];
        } else {
          //非登陆状态
          if (this.$i18n.locale === "zh") {
            this.$router.push("/cn/register");
          } else if (this.$i18n.locale === "tw") {
            this.$router.push("/tw/register");
          } else if (this.$i18n.locale === "ja") {
            this.$router.push("/ja/register");
          } else if (this.$i18n.locale === "ko") {
            this.$router.push("/ko/register");
          } else if (this.$i18n.locale === "ru") {
            this.$router.push("/ru/register");
          } else if (this.$i18n.locale === "es") {
            this.$router.push("/es/register");
          }  else if (this.$i18n.locale === "fr") {
            this.$router.push("/fr/register");
          } else if (this.$i18n.locale === "de") {
            this.$router.push("/de/register");
          }else {
            this.$router.push("/register");
          }
          this.current = ["register"];
        }
        return;
      }
      //路由跳转事件
      if (this.$i18n.locale === "zh") {
        this.$router.push("/cn/" + path);
      } else if (this.$i18n.locale === "tw") {
        this.$router.push("/tw/" + path);
      } else if (this.$i18n.locale === "ja") {
        this.$router.push("/ja/" + path);
      } else if (this.$i18n.locale === "ko") {
        this.$router.push("/ko/" + path);
      } else if (this.$i18n.locale === "ru") {
        this.$router.push("/ru/" + path);
      } else if (this.$i18n.locale === "es") {
        this.$router.push("/es/" + path);
      } else if (this.$i18n.locale === "fr") {
        this.$router.push("/fr/" + path);
      }  else if (this.$i18n.locale === "de") {
        this.$router.push("/de/" + path);
      }  else {
        this.$router.push("/" + path);
      }
      this.current = [path];
      let arr = [];
    },
    changeLang(lang, text) {
      this.currentLang = lang;
      //动态添加href
      switch (lang) {
        case "en":
          this.homeLink = "/";
          this.downloadLink = "/download";
          this.blogLink = "https://lightningxvpn.com/blog/en/";
          this.registerLink = "/register";
          this.faqLink = "/faq";
          this.loginLink = "/login";
          this.myAccountLink = "/myAccount";
          this.myPromotionLink = "/myPromotion";
          this.htmlLang = 'en';
          break;
        case "zh":
          this.homeLink = "/cn/";
          this.downloadLink = "/cn/download";
          this.blogLink = "https://lightningxvpn.com/blog/cn/";
          this.registerLink = "/cn/register";
          this.faqLink = "/cn/faq";
          this.loginLink = "/cn/login";
          this.myAccountLink = "/cn/myAccount";
          this.myPromotionLink = "/cn/myPromotion";
          this.htmlLang = 'zh';
          break;
        case "tw":
          this.homeLink = "/tw/";
          this.downloadLink = "/tw/download";
          this.blogLink = "https://lightningxvpn.com/blog/en/";
          this.registerLink = "/tw/register";
          this.faqLink = "/tw/faq";
          this.loginLink = "/tw/login";
          this.myAccountLink = "/tw/myAccount";
          this.myPromotionLink = "/tw/myPromotion";
          this.htmlLang = 'zh';
          break;
        case "ja":
          this.homeLink = "/ja/";
          this.downloadLink = "/ja/download";
          this.blogLink = "https://lightningxvpn.com/blog/en/";
          this.registerLink = "/ja/register";
          this.faqLink = "/ja/faq";
          this.loginLink = "/ja/login";
          this.myAccountLink = "/ja/myAccount";
          this.myPromotionLink = "/ja/myPromotion";
          this.htmlLang = 'ja';
          break;
        case "ko":
          this.homeLink = "/ko/";
          this.downloadLink = "/ko/download";
          this.blogLink = "https://lightningxvpn.com/blog/en/";
          this.registerLink = "/ko/register";
          this.faqLink = "/ko/faq";
          this.loginLink = "/ko/login";
          this.myAccountLink = "/ko/myAccount";
          this.myPromotionLink = "/ko/myPromotion";
          this.htmlLang = 'ko';
          break;
        case "ru":
          this.homeLink = "/ru/";
          this.downloadLink = "/ru/download";
          this.blogLink = "https://lightningxvpn.com/blog/en/";
          this.registerLink = "/ru/register";
          this.faqLink = "/ru/faq";
          this.loginLink = "/ru/login";
          this.myAccountLink = "/ru/myAccount";
          this.myPromotionLink = "/ru/myPromotion";
          this.htmlLang = 'ru';
          break;
        case "es":
          this.homeLink = "/es/";
          this.downloadLink = "/es/download";
          this.blogLink = "https://lightningxvpn.com/blog/en/";
          this.registerLink = "/es/register";
          this.faqLink = "/es/faq";
          this.loginLink = "/es/login";
          this.myAccountLink = "/es/myAccount";
          this.myPromotionLink = "/es/myPromotion";
          this.htmlLang = 'es';
          break;
          case "fr":
          this.homeLink = "/fr/";
          this.downloadLink = "/fr/download";
          this.blogLink = "https://lightningxvpn.com/blog/en/";
          this.registerLink = "/fr/register";
          this.faqLink = "/fr/faq";
          this.loginLink = "/fr/login";
          this.myAccountLink = "/fr/myAccount";
          this.myPromotionLink = "/fr/myPromotion";
          this.htmlLang = 'fr';
          break;
          case "de":
          this.homeLink = "/de/";
          this.downloadLink = "/de/download";
          this.blogLink = "https://lightningxvpn.com/blog/en/";
          this.registerLink = "/de/register";
          this.faqLink = "/de/faq";
          this.loginLink = "/de/login";
          this.myAccountLink = "/de/myAccount";
          this.myPromotionLink = "/de/myPromotion";
          this.htmlLang = 'de';
          break;
        // Add more languages as needed
        default:
          this.homeLink = "/";
          this.downloadLink = "/download";
          this.blogLink = "https://lightningxvpn.com/blog/en/";
          this.registerLink = "/register";
          this.faqLink = "/faq";
          this.loginLink = "/login";
          this.htmlLang = 'en';
      }
      Cookies.set("language", lang);
      this.isShowLang = false;
      this.$i18n.locale = lang;
      this.text = text;
      let path = location.pathname;
      let search = location.search;
      this.changeNavStyle(path);

      //改变之前的路径是否为英文
      let currentEn = true;
      if (
        path.indexOf("/cn") !== -1 ||
        path.indexOf("/tw") !== -1 ||
        path.indexOf("/ja") !== -1 ||
        path.indexOf("/ko") !== -1 ||
        path.indexOf("/ru") !== -1 ||
        path.indexOf("/es") !== -1 ||
        path.indexOf("/fr") !== -1 ||
        path.indexOf("/de") !== -1
      ) {
        currentEn = false;
      }
      //tite
      if ("zh" === lang || "tw" === lang) {
        document.title = "闪连 VPN | LightningX VPN";
      } else {
        document.title = "LightningX VPN | 闪连 VPN";
      }
      //meta description
      this.changeMeta(lang);
      this.changeAlternate(lang);
      //要保留当前path的参数
      if (lang === "zh") {
        if (path.indexOf("zh") === -1) {
          if (currentEn) {
            this.$router.push("/cn" + path + search);
          } else {
            let newPath = path.replace(/\/([^\/]+)\//, "/cn/");
            this.$router.push(newPath + search);
          }
        }
      }
      if (lang === "en") {
        if (!currentEn) {
          let newPath = path.replace(/\/([^\/]+)\//, "/");
          this.$router.push(newPath + search);
        }
      }
      //繁體中文
      if (lang === "tw") {
        if (path.indexOf("tw") === -1) {
          if (currentEn) {
            this.$router.push("/tw" + path + search);
          } else {
            let newPath = path.replace(/\/([^\/]+)\//, "/tw/");
            this.$router.push(newPath + search);
          }
        }
      }
      //日语
      if (lang === "ja") {
        if (path.indexOf("ja") === -1) {
          if (currentEn) {
            this.$router.push("/ja" + path + search);
          } else {
            let newPath = path.replace(/\/([^\/]+)\//, "/ja/");
            this.$router.push(newPath + search);
          }
        }
      }
      //韩语
      if (lang === "ko") {
        if (path.indexOf("ko") === -1) {
          if (currentEn) {
            this.$router.push("/ko" + path + search);
          } else {
            let newPath = path.replace(/\/([^\/]+)\//, "/ko/");
            this.$router.push(newPath + search);
          }
        }
      }
      //俄语
      if (lang === "ru") {
        if (path.indexOf("ru") === -1) {
          if (currentEn) {
            this.$router.push("/ru" + path + search);
          } else {
            let newPath = path.replace(/\/([^\/]+)\//, "/ru/");
            this.$router.push(newPath + search);
          }
        }
      }
      //西班牙语
      if (lang === "es") {
        if (path.indexOf("es") === -1) {
          if (currentEn) {
            this.$router.push("/es" + path + search);
          } else {
            let newPath = path.replace(/\/([^\/]+)\//, "/es/");
            this.$router.push(newPath + search);
          }
        }
      }
            //法语
            if (lang === "fr") {
        if (path.indexOf("fr") === -1) {
          if (currentEn) {
            this.$router.push("/fr" + path + search);
          } else {
            let newPath = path.replace(/\/([^\/]+)\//, "/fr/");
            this.$router.push(newPath + search);
          }
        }
      }
            //德语
            if (lang === "de") {
        if (path.indexOf("de") === -1) {
          if (currentEn) {
            this.$router.push("/de" + path + search);
          } else {
            let newPath = path.replace(/\/([^\/]+)\//, "/de/");
            this.$router.push(newPath + search);
          }
        }
      }
      this.handleGlobalClick();
    },
    changeMeta(lang) {
      // 更新描述
      var metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        if (lang === "zh") {
          metaDescription.setAttribute(
            "content",
            "VPN首选闪连VPN！不限流、不限速，低价享受多设备登录。随时浏览TikTok等社媒平台，轻松解锁奈飞等流媒体热门原声IP剧集，同时保障您的隐私安全。"
          );
        } else if (lang === "tw") {
          metaDescription.setAttribute(
            "content",
            "VPN首選閃連VPN！不限流、不限速，低價享有多設備登入。隨時瀏覽TikTok等社媒平台，輕鬆解鎖奈飛等串流熱門原聲IP劇集，同時保障您的隱私安全。"
          );
        } else {
          metaDescription.setAttribute(
            "content",
            "Download LightningX VPN to enjoy unlimited bandwidth, lightning-fast speed, affordable plans, and multi-device support. Safely browse social media sites like TikTok and unlock Netflix's hottest movies. Ensure that your privacy is protected. VPN首选闪连VPN!"
          );
        }
      }
    },
    changeNavStyle(path) {
      if (path.includes('download')) {
        this.currentNavStyle = 'download';
      } else if (path.includes('register')) {
        this.currentNavStyle = 'register';
      } else if (path.includes('faq')) {
        this.currentNavStyle = 'faq';
      } else if (path.includes('login')) {
        this.currentNavStyle = 'login';
      } else if (path.includes('myAccount')) {
        this.currentNavStyle = 'myAccount';
      } else if (path.includes('affiliate-program')) {
        this.currentNavStyle = 'affiliate-program';
      } else if (path.includes('registAgent')) {
        this.currentNavStyle = 'registAgent';
      } else if (path.includes('myPromotion')) {
        this.currentNavStyle = 'myPromotion';
      } else if (path.includes('privacyPolicy')) {
        this.currentNavStyle = 'privacyPolicy';
      } else if (path.includes('terms-of-service')) {
        this.currentNavStyle = 'terms-of-service';
      } else if (path.includes('error')) {
        this.currentNavStyle = 'notFound';
      } else {
        this.currentNavStyle = 'home';
      }

    }
  },
};
</script>

<style scoped>
  .ipinfo {
    background: #3b3b3b;
    min-height: 28px;
    line-height: 28px;
    color: #fff;
    text-align: center;
    font-size: 14px;
    position: fixed;
    z-index: 10;
    width: 100%;
    font-weight: bold;
  }
  .header-status-tooltip-show {
    font-size: 14px;
    line-height: 1.7;
    padding: 5px;
    display: none;
    position: absolute;
    min-width: 330px;
    color: #999;
    background: #eee;
    border-radius: 5px;
    z-index: 10;
    right: 0px;
  }
  .statusUnprotected:hover .show-tooltip {
    display: block;
  }
.myHeader-menu .fontStyle {
  font-weight: 700;
  color: #ff9a2e;
  border-bottom: 2px solid #ff9a2e;
}

.myHeader {
  margin-top: 28px;
  width: 100%;
  height: 64px;
  background-color: white;
  display: flex;
  justify-content: end;
  position: fixed;
  z-index: 2;
  box-shadow: 0px 3px 20px 0px rgba(224,236,241,0.5);
}

.myHeader-content {
  width: 95%;
  display: flex;
  height: 64px;
  align-items: center;
}

header .logo {
  display: inline-block;
  width: 12.375rem;
  height: 2.5rem;
  margin-left: 15px;
}

.myHeader-menu {
  flex: 1;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  z-index: 99999 !important;
}

.myHeader-menu a {
  display: inline-block;
  height: 64px;
  line-height: 64px;
  color: #262626;
  font-weight: 500;
  font-size: 16px;
  padding: 0px 20px;
  font-size: 16px;
}

.myHeader-menu a:hover {
  color: #ff9a2e;
  border-bottom: 2px solid #ff9a2e;
}

.myHeader-dropdown-menu {
  display: block;
  min-width: 120px;
  position: fixed;
  background: #fff;
  right: 10px;
  text-align: left;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  top: 72px;
  animation-name: imgMove;
  animation-duration: 0.2s;
  padding: 10px 0;
  z-index: 99999 !important;
}

.myHeader-myAccount {
  position: relative;
}

.myHeader-myAccount-menu {
  display: block;
  min-width: 120px;
  position: absolute;
  background: #fff;
  text-align: left;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  top: 72px;
  animation-name: imgMove;
  animation-duration: 0.2s;
  padding: 10px 0;
}

.myHeader-myAccount-menu a {
  display: block;
  height: 40px;
  line-height: 40px;
  font-size: 13px;
}

.myHeader-dropdown-menu::before,
.myHeader-myAccount-menu::before {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #fff;
  position: absolute;
  content: " ";
  top: -5px;
  left: 48%;
}

.myHeader-dropdown-menu .lang {
  display: block;
  height: 40px;
  line-height: 40px;
  font-size: 13px;
}

.myHeader-menu-mb {
  display: none;
  flex: 1;
  justify-content: flex-end;
}

.myHeader-drawer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
}

.myHeader-menu-submenus {
  font-size: 16px;
  padding: 0px 20px;
  font-weight: 900;
  cursor: pointer;
}

.myHeader-drawer-mb-submenus {
  width: 100%;
  background-color: #ffffff;
  padding-top: 0;
  position: relative;
  top: -100%;
  transition: all 0.4s;
  margin-top: 92px;
}

.myHeader-drawer .show {
  top: 0;
  transition: all 0.4s;
}

.myHeader-drawer-mb-submenus a {
  display: inline-block;
  width: 100%;
  text-align: left;
  padding: 10px 30px;
  font-size: 16px;
  font-weight: 600;
  border-top: 1px solid #e0e0e0;
}

@media screen and (max-width: 1279px) {
  .myHeader-menu {
    display: none;
  }

  .myHeader-menu-mb {
    display: flex;
    align-items: center;
    height: 64px;
  }
}
</style>
