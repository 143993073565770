import axios from 'axios'
import {Popconfirm, message} from 'ant-design-vue'
import {getToken} from '@/utils/auth'
import errorCode from '@/utils/errorCode'
import store from '@/store'
import $i18n from "@/lang";
import CryptoJS from "crypto-js";

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 是否显示重新登录
export let isRelogin = {show: false};

let baseUrl;
if (process.env.NODE_ENV === 'production') {
    let domain = window.location.hostname;
    domain = domain.replace(/^www\./, '').replace(/^link\./,'');
    baseUrl = `https://api.${domain}`;
} else {
    baseUrl = process.env.VUE_APP_BASE_API;
}

const service = axios.create({
    baseURL: baseUrl,
    // baseURL: process.env.VUE_APP_BASE_API,
    timeout: 50000
})

const key = "6HA&)NZ*VsjwW8bJ";
const options = {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
};
function encryptData(data) {
    const dataBytes = CryptoJS.enc.Utf8.parse(data);
    const key2 = CryptoJS.enc.Utf8.parse(key);
    const encrypted = CryptoJS.AES.encrypt(dataBytes, key2, options);
    const encryptedString = encrypted.toString();
    return encryptedString;
}
// 添加请求拦截器
service.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false
    if (getToken() && !isToken) {
        config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    //根据国际化 确定语言
    if ($i18n.locale === 'zh') {
        config.headers['Accept-Language'] = 'zh-CN'
    } else if ($i18n.locale === 'en') {
        config.headers['Accept-Language'] = 'en'
    } else if ($i18n.locale === 'tw') {
        config.headers['Accept-Language'] = 'zh-Hant-HK'
    } else if ($i18n.locale === 'ja') {
        config.headers['Accept-Language'] = 'ja'
    } else if ($i18n.locale === 'ko') {
        config.headers['Accept-Language'] = 'ko'
    } else if ($i18n.locale === 'ru') {
        config.headers['Accept-Language'] = 'ru'
    } else if ($i18n.locale === 'fr') {
        config.headers['Accept-Language'] = 'fr'
    }else if ($i18n.locale === 'de') {
        config.headers['Accept-Language'] = 'de'
    }else {
        config.headers['Accept-Language'] = 'en-US'
    }
    config.headers['shanlian'] = '1'
    //
    config.headers['lightningxx'] = encryptData('web&'+ Date.now() + '&web&v1.0')
    return config
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
})

// 添加响应拦截器
// 响应拦截器
service.interceptors.response.use(res => {
        // 未设置状态码则默认成功状态
        const code = res.data.code || 200;
        // 获取错误信息
        const msg = errorCode[code] || res.data.msg || errorCode['default']
        // 二进制数据则直接返回
        if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
            return res.data
        }
        if (code === 401) {
            if ($i18n.locale === 'zh') {
                message.error('会话无效，或者会话已过期。请重新登录。')
                store.dispatch('LogOut').then(() => {
                    location.href = '/cn/login';
                })
            } else {
                message.error('Invalid session, or the session has expired. Please log in again.')
                store.dispatch('LogOut').then(() => {
                    location.href = '/login';
                })
            }
        } else if (code === 444) {
            return Promise.reject({msg: msg, code: code})
        } else if (code === 440) {
            return Promise.reject({msg: msg, code: code})
        } else if (code === 500) {
            message.error(msg)
            return Promise.reject(msg)
        } else if (code === 601) {
            message.warning(msg)
            return Promise.reject('error')
        } else if (code !== 200) {
            message.error(msg)
            return Promise.reject('error')
        } else {
            return res.data
        }
    },
    error => {
        console.log('err' + error)
        let {msg} = error;
        if (msg == "Network Error") {
            msg = "Abnormal backend interface connection";
        } else if (msg.includes("timeout")) {
            msg = "System interface request timeout";
        } else if (msg.includes("Request failed with status code")) {
            msg = "system interface " + msg.substr(msg.length - 3) + "abnormal";
        }
        message.error(msg)
        return Promise.reject(error)
    }
)

export default service
