<template>
  <div class="nav-style">
    <a-menu
      mode="horizontal"
      style="height: 64px; line-height: 64px"
      @click="menuClick"
      :selectedKeys="current"
    >
      <a-menu-item
        key="homelogo"
        id="logoPic"
        aria-label="logoname"
        style="float: left; text-align: left; padding-left: 0px"
      >
        <div class="logoDiv">
          <div style="width: 200px"></div>
          <img
            id="logoImg"
            alt=""
            src="../../assets/img/shanlian_logo_zh.png"
            v-if="this.$i18n.locale == 'zh'"
          />
          <img
            id="logoImgTw"
            alt=""
            src="../../assets/img/shanlian_logo_zh.png"
            v-else-if="this.$i18n.locale == 'tw'"
          />
          <img
            id="logoImgEn"
            alt=""
            src="../../assets/img/shanlian_logo_en.png"
            v-else
          />
        </div>
      </a-menu-item>
      <a-menu-item key="home">
        {{ $t("header.home") }}
      </a-menu-item>
      <a-menu-item key="download">
        {{ $t("header.download") }}
      </a-menu-item>
      <a-menu-item key="blog">
        {{ $t("header.blog") }}
      </a-menu-item>
      <a-menu-item key="register">
        {{ $t("header.vip") }}
      </a-menu-item>
      <a-menu-item key="faq">
        {{ $t("header.helpCenter") }}
      </a-menu-item>
      <a-menu-item key="login" v-if="!listenUserState">
        {{ $t("header.signIn") }}
      </a-menu-item>
      <a-sub-menu id="ssss" v-else>
        <span slot="title" class="submenu-title-wrapper">
          <a-icon type="menu-fold" />
          {{ $t("header.myAccount") }}
        </span>
        <a-menu-item key="myAccount">
          {{ $t("header.myAccount") }}
        </a-menu-item>
        <a-menu-item key="myPromotion" v-if="isProxy">
          {{ $t("header.promotionAgency") }}
        </a-menu-item>

        <a-menu-item key="signout">
          {{ $t("header.signOut") }}
        </a-menu-item>
      </a-sub-menu>
      <a-sub-menu id="subLang">
        <span slot="title" class="submenu-title-wrapper"> {{ text }}</span>
        <a-menu-item key="setting:1" @click="changeLang('en', 'English')">
          English
        </a-menu-item>
        <a-menu-item key="setting:2" @click="changeLang('zh', '简体中文')">
          简体中文
        </a-menu-item>
        <a-menu-item key="setting:3" @click="changeLang('tw', '繁體中文')">
          繁體中文
        </a-menu-item>
        <a-menu-item key="setting:4" @click="changeLang('ja', '日本語')">
          日本語
        </a-menu-item>
        <a-menu-item key="setting:5" @click="changeLang('ko', '한국어')">
          한국어
        </a-menu-item>
        <a-menu-item key="setting:6" @click="changeLang('ru', 'русский')">
          русский
        </a-menu-item>
        <!-- <a-menu-item key="setting:7" @click="changeLang('es', 'españa')">
          España
        </a-menu-item> -->
      </a-sub-menu>
    </a-menu>
  </div>
</template>
<script>
import { getToken, setToken, removeToken } from "@/utils/auth";
import Cookies from "js-cookie";

export default {
  name: "Header",
  data() {
    return {
      current: [],
      menuKeys: "home,download,faq,myAccount,signOut",
      text: "English",
      account: "My Account",
      logoSrc: "../../assets/img/orca_logo.png",
      userStatus: false,
      browserLanguage: "",
      isProxy: false,
    };
  },
  computed: {
    haveTk: {
      get() {
        return getToken() !== undefined && getToken() !== "";
      },
      set() {},
    },
    listenUserState: {
      get() {
        return this.$store.getters.token;
      },
      set() {},
    },
  },
  watch: {
    $route: "menuActive",
  },
  updated() {
    const status = localStorage.getItem("status");
    if (status !== "normal") {
      this.isProxy = true;
    } else {
      this.isProxy = false;
    }
  },
  created() {
    this.langListen();
  },
  beforeDestroy() {},
  methods: {
    langListen() {
      let path = location.pathname;
      let search = location.search;
      // ad 页面不参与
      if (path.indexOf("ad") != -1 && path.indexOf("download") == -1) {
        return;
      }
      //eula不参与
      if (path.indexOf("eula") != -1) {
        return;
      }
      if (path.indexOf("robots") != -1) {
        return;
      }
      if (path.indexOf("sitemap") != -1) {
        return;
      }
      //blog 特殊处理
      if (path.indexOf("blog") != -1) {
        return;
      }
      //blog 特殊处理
      if (path.indexOf("cooperation") != -1) {
        return;
      }
      // if (path.indexOf('blog') != -1 && path.indexOf('article') === -1) {
      //     this.changeLang('zh', '简体中文');
      //     return;
      // }
      //跳转参数带上指定语言 优先级最高
      if (search.indexOf("lang") !== -1) {
        if (search.indexOf("zh-CN") !== -1) {
          this.changeLang("zh", "简体中文");
        } else if (search.indexOf("zh-Hant-HK") !== -1) {
          this.changeLang("tw", "繁體中文");
        } else if (search.indexOf("ja") !== -1) {
          this.changeLang("ja", "日本語");
        } else if (search.indexOf("ko") !== -1) {
          this.changeLang("ko", "한국어");
        } else if (search.indexOf("ru") !== -1) {
          this.changeLang("ru", "русский");
        }  else if (search.indexOf("es") !== -1) {
          this.changeLang("es", "españa");
        }else {
          this.changeLang("en", "English");
        }
        return;
      }
      //url路径上是否携带非英文的语言路径参数
      if (path.startsWith("/cn")) {
        this.changeLang("zh", "简体中文");
        return;
      }
      if (path.startsWith("/tw")) {
        this.changeLang("tw", "繁體中文");
        return;
      }
      if (path.startsWith("/ja")) {
        this.changeLang("ja", "日本語");
        return;
      }
      if (path.startsWith("/ko")) {
        this.changeLang("ko", "한국어");
        return;
      }
      if (path.startsWith("/ru")) {
        this.changeLang("ru", "русский");
        return;
      }
      if (path.startsWith("/es")) {
        this.changeLang("es", "españa");
        return;
      }
      //未手动设置过语言 初次进入，根据浏览器语言决定
      let cookLang = Cookies.get("language");
      if (!cookLang) {
        if ("zh-CN" === navigator.language) {
          this.changeLang("zh", "简体中文");
        } else if (
          "zh-Hant" === navigator.language ||
          "zh-TW" === navigator.language
        ) {
          this.changeLang("tw", "繁體中文");
        } else if (
          "ja" === navigator.language.toLowerCase() ||
          "ja-" === navigator.language.toLowerCase()
        ) {
          this.changeLang("ja", "日本語");
        } else if (
          "ko" === navigator.language.toLowerCase() ||
          "ko-" === navigator.language.toLowerCase()
        ) {
          this.changeLang("ko", "한국어");
        } else if (
          "ru" === navigator.language.toLowerCase() ||
          "ru-" === navigator.language.toLowerCase()
        ) {
          this.changeLang("es", "españa");
        }else if (
          "es" === navigator.language.toLowerCase() ||
          "es-" === navigator.language.toLowerCase()
        ) {
          this.changeLang("ja", "日本語");
        }  else {
          this.changeLang("en", "English");
        }
        return;
      } else {
        //设置过cookies
        if ("zh" == cookLang) {
          this.changeLang("zh", "简体中文");
        } else if ("tw" === cookLang) {
          this.changeLang("tw", "繁體中文");
        } else if ("ja" === cookLang) {
          this.changeLang("ja", "日本語");
        } else if ("ko" === cookLang) {
          this.changeLang("ko", "한국어");
        } else if ("ru" === cookLang) {
          this.changeLang("ru", "русский");
        } else if ("es" === cookLang) {
          this.changeLang("es", "españa");
        } else {
          this.changeLang("en", "English");
        }
        return;
      }
    },
    menuActive() {
      let path = location.pathname;
      let pathKey = path
        .replaceAll("/", "")
        .replace("cn", "")
        .replace("tw", "")
        .replace("ja", "")
        .replace("ko", "")
        .replace("ru", "")
        .replace("es", "");
      if (pathKey && pathKey.indexOf(this.menuKeys !== -1)) {
        this.current = [pathKey];
      } else {
        this.current = [];
      }
      this.changeCnonical(pathKey);
      //gtag 记录
      gtag("event", "page_view");
      //下载页面记录
      if ("download" === pathKey) {
        gtag("event", "view_download_page");
      }
      if ("register" === pathKey) {
        gtag("event", "view_register_page");
      }

    },
    changeCnonical(path) {
      // 选中页面中的第一个 <link rel="canonical"> 元素
      var canonicalLink = document.querySelector('link[rel="canonical"]');
      // 检查是否找到了元素
      if (canonicalLink) {
        canonicalLink.setAttribute("href", "https://lightningxvpn.com/" + path);
      }
    },
    changeAlternate(lang) {
      // 获取所有 <link rel="alternate"> 标签
      const alternateLinks = document.querySelectorAll('link[rel="alternate"]');
      // 检查是否存在至少两个这样的标签
      if (alternateLinks.length >= 2) {
        // 修改第二个标签的 hreflang 属性
        if ('en' === lang) {
          alternateLinks[1].setAttribute('href', "https://lightningxvpn.com/");
        } else {
          if ('zh' === lang) {
            alternateLinks[1].setAttribute('href', "https://lightningxvpn.com/cn/");
          } else {
            alternateLinks[1].setAttribute('href', "https://lightningxvpn.com/" + lang + "/");
          }
        }
        let hreflang = "en"
        if ("zh" === lang) {
           hreflang = "zh-CN"
        } else if ("tw" === lang) {
          hreflang = "zh-TW"
        } else if ("ja" === lang) {
          hreflang = "ja"
        } else if ("ko" === lang) {
          hreflang = "ko"
        } else if ("ru" === lang) {
          hreflang = "ru"
        } else {
          hreflang = "en"
        }
        alternateLinks[1].setAttribute('hreflang', hreflang);
      }
    },
    online() {
      if (getToken() === undefined || getToken() === "") {
        return false;
      } else {
        return true;
      }
    },
    menuClick(item) {
      let path = item.key;
      if (path.indexOf("setting") !== -1) {
        return;
      }
      if (path == "homelogo") {
        path = "home";
      }
      //blog 特殊处理
      if (path == "blog") {
        if (this.$i18n.locale === "zh") {
          window.location.href = "https://lightningxvpn.com/blog";
        } else {
          window.location.href = "https://lightningxvpn.com/blog/en/";
        }
        // this.$router.push('/blog')
        return;
      }
      if (path === "signout") {
        this.$store
          .dispatch("LogOut")
          .then(() => {
            if (this.$i18n.locale === "zh") {
              this.$router.push("/cn/login");
            } else if (this.$i18n.locale === "tw") {
              this.$router.push("/tw/login");
            } else if (this.$i18n.locale === "ja") {
              this.$router.push("/ja/login");
            } else if (this.$i18n.locale === "ko") {
              this.$router.push("/ko/login");
            } else if (this.$i18n.locale === "ru") {
              this.$router.push("/ru/login");
            } else if (this.$i18n.locale === "es") {
              this.$router.push("/es/login");
            } else {
              this.$router.push("/login");
            }
            this.current = ["login"];
            //合作代理
            localStorage.removeItem("status");
            this.isProxy = false;
          })
          .catch(() => {});
        return;
      }
      //购买 登陆和非登陆路由
      if (path == "register") {
        //登陆状态
        if (this.listenUserState) {
          if (this.$i18n.locale === "zh") {
            this.$router.push("/cn/myAccount?renew=3");
          } else if (this.$i18n.locale === "tw") {
            this.$router.push("/tw/myAccount?renew=3");
          } else if (this.$i18n.locale === "ja") {
            this.$router.push("/ja/myAccount?renew=3");
          } else if (this.$i18n.locale === "ko") {
            this.$router.push("/ko/myAccount?renew=3");
          } else if (this.$i18n.locale === "ru") {
            this.$router.push("/ru/myAccount?renew=3");
          } else if (this.$i18n.locale === "es") {
            this.$router.push("/es/myAccount?renew=3");
          }else {
            this.$router.push("/myAccount?renew=3");
          }
          this.current = ["myAccount"];
        } else {
          //非登陆状态
          if (this.$i18n.locale === "zh") {
            this.$router.push("/cn/register");
          } else if (this.$i18n.locale === "tw") {
            this.$router.push("/tw/register");
          } else if (this.$i18n.locale === "ja") {
            this.$router.push("/ja/register");
          } else if (this.$i18n.locale === "ko") {
            this.$router.push("/ko/register");
          } else if (this.$i18n.locale === "ru") {
            this.$router.push("/ru/register");
          } else if (this.$i18n.locale === "es") {
            this.$router.push("/es/register");
          } else {
            this.$router.push("/register");
          }
          this.current = ["register"];
        }
        return;
      }
      //路由跳转事件
      if (this.$i18n.locale === "zh") {
        this.$router.push("/cn/" + path);
      } else if (this.$i18n.locale === "tw") {
        this.$router.push("/tw/" + path);
      } else if (this.$i18n.locale === "ja") {
        this.$router.push("/ja/" + path);
      } else if (this.$i18n.locale === "ko") {
        this.$router.push("/ko/" + path);
      } else if (this.$i18n.locale === "ru") {
        this.$router.push("/ru/" + path);
      } else if (this.$i18n.locale === "es") {
        this.$router.push("/es/" + path);
      } else {
        this.$router.push("/" + path);
      }
      this.current = [path];
    },
    changeLang(lang, text) {
      Cookies.set("language", lang);
      this.$i18n.locale = lang;
      this.text = text;
      let path = location.pathname;
      let search = location.search;
      //改变之前的路径是否为英文
      let currentEn = true;
      if (
        path.indexOf("/cn") !== -1 ||
        path.indexOf("/tw") !== -1 ||
        path.indexOf("/ja") !== -1 ||
        path.indexOf("/ko") !== -1 ||
        path.indexOf("/ru") !== -1 ||
        path.indexOf("/es") !== -1
      ) {
        currentEn = false;
      }
      //tite
      if ("zh" === lang || "tw" === lang) {
        document.title = "闪连 VPN | LightningX VPN";
      } else {
        document.title = "LightningX VPN | 闪连 VPN";
      }
      //meta description
      this.changeMeta(lang);
      this.changeAlternate(lang)
      //要保留当前path的参数
      if (lang === "zh") {
        if (path.indexOf("cn") === -1) {
          if (currentEn) {
            this.$router.push("/cn" + path + search);
          } else {
            let newPath = path.replace(/\/([^\/]+)\//, "/cn/");
            this.$router.push(newPath + search);
          }
        }
      }
      if (lang === "en") {
        if (!currentEn) {
          let newPath = path.replace(/\/([^\/]+)\//, "/");
          this.$router.push(newPath + search);
        }
      }
      //繁體中文
      if (lang === "tw") {
        if (path.indexOf("tw") === -1) {
          if (currentEn) {
            this.$router.push("/tw" + path + search);
          } else {
            let newPath = path.replace(/\/([^\/]+)\//, "/tw/");
            this.$router.push(newPath + search);
          }
        }
      }
      //日语
      if (lang === "ja") {
        if (path.indexOf("ja") === -1) {
          if (currentEn) {
            this.$router.push("/ja" + path + search);
          } else {
            let newPath = path.replace(/\/([^\/]+)\//, "/ja/");
            this.$router.push(newPath + search);
          }
        }
      }
      //韩语
      if (lang === "ko") {
        if (path.indexOf("ko") === -1) {
          if (currentEn) {
            this.$router.push("/ko" + path + search);
          } else {
            let newPath = path.replace(/\/([^\/]+)\//, "/ko/");
            this.$router.push(newPath + search);
          }
        }
      }
      //俄语
      if (lang === "ru") {
        if (path.indexOf("ru") === -1) {
          if (currentEn) {
            this.$router.push("/ru" + path + search);
          } else {
            let newPath = path.replace(/\/([^\/]+)\//, "/ru/");
            this.$router.push(newPath + search);
          }
        }
      }
      //西班牙语
      if (lang === "es") {
        if (path.indexOf("es") === -1) {
          if (currentEn) {
            this.$router.push("/es" + path + search);
          } else {
            let newPath = path.replace(/\/([^\/]+)\//, "/es/");
            this.$router.push(newPath + search);
          }
        }
      }
    },
    changeMeta(lang) {
      // 更新描述
      var metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        if (lang === "zh") {
          metaDescription.setAttribute(
            "content",
            "VPN首选闪连VPN！不限流、不限速，低价享受多设备登录。随时浏览TikTok等社媒平台，轻松解锁奈飞等流媒体热门原声IP剧集，同时保障您的隐私安全。"
          );
        } else if (lang === "tw") {
          metaDescription.setAttribute(
            "content",
            "VPN首選閃連VPN！不限流、不限速，低價享有多設備登入。隨時瀏覽TikTok等社媒平台，輕鬆解鎖奈飛等串流熱門原聲IP劇集，同時保障您的隱私安全。"
          );
        } else {
          metaDescription.setAttribute(
            "content",
            "Download LightningX VPN to enjoy unlimited bandwidth, lightning-fast speed, affordable plans, and multi-device support. Safely browse social media sites like TikTok and unlock Netflix's hottest movies. Ensure that your privacy is protected. VPN首选闪连VPN!"
          );
        }
      }
    },
  },
};
</script>
<style scoped>
.ant-menu {
  font-size: 16px !important;
  font-weight: bold !important;
}

#logoPic {
  border-bottom: 0px !important;
}

#logoPic:hover {
  color: transparent !important;
  border-bottom: 0px !important;
}

.ant-menu-item-active {
  color: #ff9a2e !important;
  border-bottom: 2px solid #ff9a2e !important;
}

.ant-menu-item-selected {
  color: #ff9a2e !important;
  border-bottom: 2px solid #ff9a2e !important;
}

.ant-menu-submenu-selected {
  color: #ff9a2e !important;
  border-bottom: 2px solid #ff9a2e !important;
}

.ant-menu-submenu-active {
  color: #ff9a2e !important;
  border-bottom: 2px solid #ff9a2e !important;
}

::v-deep .ant-menu-submenu-title:hover {
  color: #ff9a2e !important;
}

.logoDiv {
  width: 175px;
  height: 64px;
  float: left;
  text-align: left;
}
.logoDiv img {
  width: 100%;
  margin-left: 10px;
}
</style>
