<template>
  <div id="app" class="components-layout-demo-basic">

    <a-layout style="min-height: 100vh; background: white">
      <!-- <a-layout-header
        id="flashHeader"
        :style="{
          zIndex: 1,
          width: '100%',
          padding: '0px',
          textAlign: 'right',
          background: 'white',
          position: 'fixed',
        }"
      >
        <Header />
      </a-layout-header> -->
      <MyHeader />
      <a-layout-content id="flashMain" :style="{ flex: 1, background: '#F9FBFC', marginTop: '92px' }">
        <router-view />
      </a-layout-content>
      <a-row type="flex">
        <!-- pc -->
        <a-col :xs="0" :sm="0" :md="0" :lg="24" :xl="24" :xxl="24">
          <a-layout-footer class="flashFooter" :style="{ background: '#252525', width: '100%', color: 'white' }">
            <a-row type="flex" justify="space-around" style="
                min-height: 320px;
                padding-top: 30px;
                width: 85%;
                margin: auto;
              ">
              <a-col :xs="0" :sm="9" :md="8" :lg="8" :xl="8" :xxl="6">
                <img alt="" v-if="this.$i18n.locale == 'zh'" src="./assets/img/shanlian_footer_zh.png"
                  style="width: 90%" />
                <img alt="" v-else-if="this.$i18n.locale == 'tw'" src="./assets/img/shanlian_footer_zh.png"
                  style="width: 90%" />
                <img alt="" v-else src="./assets/img/shanlian_footer_en.png" style="width: 90%" />
                <div style="
                    margin-top: 30px;
                    text-align: left;
                    font-weight: bold;
                    font-size: 18px;
                    padding-left: 10px;
                  ">
                  Connect with us
                </div>
                <div style="text-align: left; padding: 10px 10px 20px 10px;font-weight: 600;">
                  Email : support@lightningxvpn.com
                </div>
                <div class="connectUs">
                  <a class="connect-item" title="Twitter" href="https://twitter.com/LightningxVPN" target="_black">
                    <img src="./assets/img/footer_icon2.png" alt="" />
                  </a>
                  <a class="connect-item" title="Instagram" href="https://www.instagram.com/lightningx_vpn/"
                    target="_black">
                    <img src="./assets/img/footer_icon3.png" alt="" />
                  </a>
                  <a class="connect-item" title="YouTube" href="https://www.youtube.com/@LightningX_VPN"
                    target="_black">
                    <img src="./assets/img/footer_icon4.png" alt="" />
                  </a>
                  <a class="connect-item" title="Telegram Channel" href="https://t.me/+2sxVLzM-aWdjOGI9"
                    target="_black">
                    <img src="./assets/img/footer_icon5.png" alt="" />
                  </a>
                  <a class="connect-item" title="Telegram Group" href="https://t.me/+5GwcnDbege0yZWE1" target="_black">
                    <img src="./assets/img/footer_icon5.png" alt="" />
                  </a>
                  <div class="foot-guarantee">
                    <img src="./assets/img/home/free_icon1.png" alt=""
                      style="width: 25px;height: 25px;margin-top: -5px">
                      {{ $t("footer.guarantee") }}
                  </div>
                </div>
              </a-col>
              <a-col :xs="0" :sm="0" :md="3" :lg="3" :xl="3" :xxl="5"> </a-col>
              <a-col :xs="0" :sm="3" :md="3" :lg="3" :xl="3" :xxl="3">
                <div class="footer1">{{ $t("footer.download") }}</div>
                <div class="footer2" @click="goIosHelp">iOS</div>
                <div class="footer2" @click="androidDownload">Android</div>
                <div class="footer2" @click="macosDownload">macOS</div>
                <div class="footer2" @click="windowsDownload">Windows</div>
              </a-col>
              <a-col :xs="0" :sm="3" :md="3" :lg="3" :xl="3" :xxl="3">
                <div class="footer1">{{ $t("footer.helpCenter") }}</div>
                <div class="footer2" @click="gofaq(1)">
                  {{ $t("footer.productFaqs") }}
                </div>
                <div class="footer2" @click="gofaq(5)">
                  {{ $t("footer.ios_help") }}
                </div>
                <div class="footer2" @click="gofaq(7)">
                  {{ $t("footer.apk_help") }}
                </div>
                <div class="footer2" @click="gofaq(6)">
                  {{ $t("footer.mac_help") }}
                </div>
                <div class="footer2" @click="gofaq(4)">
                  {{ $t("footer.win_help") }}
                </div>
              </a-col>
              <a-col :xs="0" :sm="3" :md="3" :lg="3" :xl="3" :xxl="3">
                <div class="footer1">{{ $t("footer.about") }}</div>
                <div class="footer2" @click="goBlog">{{ $t("header.blog") }}</div>
                <div class="footer2" @click="goPP">{{ $t("footer.pp") }}</div>
                <div class="footer2" @click="goTs">{{ $t("footer.ts") }}</div>
              </a-col>
              <a-col :xs="0" :sm="3" :md="3" :lg="3" :xl="3" :xxl="3">
                <div class="footer1">{{ $t("footer.cooperation") }}</div>
                <div class="footer2" @click="goFranchise">
                  {{ $t("footer.franchise") }}
                </div>
              </a-col>
            </a-row>
            <div>
              <a-divider></a-divider>
            </div>
            © 2024 LightningX VPN. All rights reserved.
          </a-layout-footer>
        </a-col>
        <!-- mb -->
        <a-col :xs="24" :sm="24" :md="24" :lg="0" :xl="0" :xxl="0">
          <a-layout-footer class="flashFooter" :style="{
            minHeight: '370px',
            background: '#252525',
            width: '100%',
            color: 'white',
            padding: '25px',
          }">
            <div style="text-align: left">
              <img alt="" v-if="this.$i18n.locale == 'zh'" src="./assets/img/shanlian_footer_zh.png"
                style="width: 270px" />
              <img alt="" v-else-if="this.$i18n.locale == 'tw'" src="./assets/img/shanlian_footer_zh.png"
                style="width: 270px" />
              <img alt="" v-else src="./assets/img/shanlian_footer_en.png" style="width: 270px" />
            </div>
            <div style="
                margin-top: 30px;
                text-align: left;
                font-weight: bold;
                font-size: 18px;
                padding-left: 10px;
              ">
              Connect with us
            </div>
            <div style="text-align: left; padding: 20px 10px">
              Email : support@lightningxvpn.com
            </div>
            <div class="connectUs">
              <a class="connect-item" title="Twitter" href="https://twitter.com/LightningxVPN" target="_black">
                <img src="./assets/img/footer_icon2.png" alt="" />
              </a>
              <a class="connect-item" title="Instagram" href="https://www.instagram.com/lightningx_vpn/"
                target="_black">
                <img src="./assets/img/footer_icon3.png" alt="" />
              </a>
              <a class="connect-item" title="YouTube" href="https://www.youtube.com/@LightningX_VPN" target="_black">
                <img src="./assets/img/footer_icon4.png" alt="" />
              </a>
              <a class="connect-item" title="Telegram Channel" href="https://t.me/+2sxVLzM-aWdjOGI9" target="_black">
                <img src="./assets/img/footer_icon5.png" alt="" />
              </a>
              <a class="connect-item" title="Telegram Group" href="https://t.me/+5GwcnDbege0yZWE1" target="_black">
                <img src="./assets/img/footer_icon5.png" alt="" />
              </a>

            </div>
            <div style="text-align: left; padding: 20px 10px;color: #969696;">
              <img src="./assets/img/home/free_icon1.png" alt="" style="width: 25px;height: 25px;margin-top: -5px">
              {{ $t("footer.guarantee") }}
            </div>
            <div>
              <a-row type="flex" justify="space-around" style="margin-top: 30px">
                <a-col :span="10">
                  <div class="footer1">{{ $t("footer.download") }}</div>
                  <div class="footer2" @click="goIosHelp">iOS</div>
                  <div class="footer2" @click="androidDownload">Android</div>
                  <div class="footer2" @click="macosDownload">macOS</div>
                  <div class="footer2" @click="windowsDownload">Windows</div>
                </a-col>
                <a-col :span="10">
                  <div class="footer1">{{ $t("footer.helpCenter") }}</div>
                  <div class="footer2" @click="gofaq(1)">
                    {{ $t("footer.productFaqs") }}
                  </div>
                  <div class="footer2" @click="gofaq(5)">
                    {{ $t("footer.ios_help") }}
                  </div>
                  <div class="footer2" @click="gofaq(7)">
                    {{ $t("footer.apk_help") }}
                  </div>
                  <div class="footer2" @click="gofaq(6)">
                    {{ $t("footer.mac_help") }}
                  </div>
                  <div class="footer2" @click="gofaq(4)">
                    {{ $t("footer.win_help") }}
                  </div>
                </a-col>
              </a-row>
              <a-row type="flex" justify="space-around" style="margin-top: 30px">
                <a-col :span="10">
                  <div class="footer1">{{ $t("footer.about") }}</div>
                  <div class="footer2" @click="goPP">{{ $t("footer.pp") }}</div>
                  <div class="footer2" @click="goTs">{{ $t("footer.ts") }}</div>
                </a-col>
                <a-col :span="10" style="text-align: right">
                  <div class="footer1">{{ $t("footer.cooperation") }}</div>
                  <div class="footer2" @click="goFranchise">
                    {{ $t("footer.franchise") }}
                  </div>
                </a-col>
              </a-row>
            </div>
            <div>
              <a-divider></a-divider>
            </div>
            © 2024 LightningX VPN. All rights reserved.
          </a-layout-footer>
        </a-col>
      </a-row>
    </a-layout>
      <div id="cookie-popup" v-show="cookieConsent">
        <div>
          <p v-html="$t('cookiesPolicy.desc')"></p>
          <div class="buttons">
            <button id="accept-btn">{{ $t("cookiesPolicy.btnAllow") }}</button>
          </div>
        </div>

      </div>
  </div>
</template>

<script>
import Header from "@/components/header/Header.vue";
import $i18n from "@/lang";
import DisableDevtool from "disable-devtool";
import MyHeader from './components/header/MyHeader.vue';

    export default {
        components: {
            Header,
            MyHeader
        },
        data() {
            return {
                cookieConsent: document.cookie.indexOf('cookie-consent') === -1
            };
        },
        computed: {},
        created() {
            // DisableDevtool({
            //   md5: "75ac2c91d93efba8651671f18ec013d0",
            //   url: "https://www.baidu.com/",
            //   ignore: () => {
            //     return navigator.userAgent.toLowerCase().includes('pagespeed') || navigator.userAgent.toLowerCase().includes('lighthouse') || navigator.userAgent.toLowerCase().includes('bot'); // 当是pagespeed忽略禁用
            //   }
            // });
            document.addEventListener("DOMContentLoaded", function () {
                var acceptBtn = document.getElementById("accept-btn");
                // var declineBtn = document.getElementById("decline-btn");

                acceptBtn.addEventListener("click", function () {
                    setCookie("cookie-consent", true, 7); // Set cookie with 30 days expiration
                    hidePopup();
                    gtag('config', 'G-PT7QDVGC57');
                });

                // declineBtn.addEventListener("click", function () {
                //     setCookie("cookie-consent", false, 7); // Set cookie with 30 days expiration
                //     hidePopup();
                //     // 用户拒绝使用 cookies
                //     gtag('consent', 'update', {
                //         'ad_storage': 'denied',
                //         'analytics_storage': 'denied'
                //     });
                // });

                function setCookie(name, value, days) {
                    var expires = new Date();
                    expires.setTime(expires.getTime() + (days * 24 * 60 * 60 * 1000));
                    document.cookie = name + "=" + value + ";expires=" + expires.toUTCString() + ";path=/";
                }

                function hidePopup() {
                    var popup = document.getElementById("cookie-popup");
                    popup.style.display = "none";
                }
            });
            //gtag 配置


        },
        beforeDestroy() {
        },
        methods: {
            gtagSetAllow() {
                // 用户允许使用 cookies
                gtag('config', 'G-PT7QDVGC57');
            },
            gtagSetDenied() {
                // 用户拒绝使用 cookies
                gtag('consent', 'update', {
                    'ad_storage': 'denied',
                    'analytics_storage': 'denied'
                });
            },
            gofaq(key) {
                if ("zh" === $i18n.locale) {
                    this.$router.push("/cn/faq?key=" + key);
                } else if ("en" === $i18n.locale) {
                    this.$router.push("/faq?key=" + key);
                } else {
                    this.$router.push("/" + $i18n.locale + "/faq?key=" + key);
                }
            },
            androidDownload() {
                window.open(this.$basedownloadUrl + "/lightningx.apk");
                gtag("event", "click_apkDownloadBtn_official");
            },
            macosDownload() {
                window.open(this.$basedownloadUrl + "/lightningx.dmg");
                gtag("event", "click_macDownloadBtn_official");
                if ("zh" === $i18n.locale) {
                    this.$router.push("/cn/faq?key=6");
                } else if ("en" === $i18n.locale) {
                    this.$router.push("/faq?key=6");
                } else {
                    this.$router.push("/" + $i18n.locale + "/faq?key=6");
                }
            },
            windowsDownload() {
                window.open(this.$basedownloadUrl + "/lightningx.exe");
                gtag("event", "click_windowsDownloadBtn_official");
                if ("zh" === $i18n.locale) {
                    this.$router.push("/cn/faq?key=4");
                } else if ("en" === $i18n.locale) {
                    this.$router.push("/faq?key=4");
                } else {
                    this.$router.push("/" + $i18n.locale + "/faq?key=4");
                }
            },
            goIosHelp() {
                window.open("https://apps.apple.com/app/lightningx-vpn/id6476379983");
                gtag("event", "click_iosDownloadBtn_official");
                this.$router.push("/cn/ios-help");
            },
            goPP() {
                if ("zh" === $i18n.locale) {
                    window.open("/cn/privacyPolicy");
                } else if ("en" === $i18n.locale) {
                    window.open("/privacyPolicy");
                } else {
                    window.open("/" + $i18n.locale + "/privacyPolicy");
                }
            },
            goBlog() {
                if ("zh" === $i18n.locale) {
                    window.open("https://lightningxvpn.com/blog/cn/");
                } else {
                    window.open("https://lightningxvpn.com/blog/en/");
                }
            },
            goTs() {
                if ("zh" === $i18n.locale) {
                    window.open("/cn/terms-of-service");
                } else if ("en" === $i18n.locale) {
                    window.open("/terms-of-service");
                } else {
                    window.open("/" + $i18n.locale + "/terms-of-service");
                }
            },
            goFranchise() {
                if ("zh" === $i18n.locale) {
                    this.$router.push("/cn/affiliate-program");
                } else if ("en" === $i18n.locale) {
                    this.$router.push("/affiliate-program");
                } else {
                    this.$router.push("/" + $i18n.locale + "/affiliate-program");
                }
            },
        },
    };
</script>

<style>
.foot-guarantee {
  text-align: left;
  padding: 20px 10px;
  font-weight: 600;
  color: #969696;
  border-radius: 10px;
  margin-top: 15px
}

#cookie-popup {
  background-color: white;
  position: fixed;
  bottom: 0px;
  width: 100%;
  padding: 20px 20px;
  box-shadow: 0px -3px 15px 0px rgba(0,0,0,0.05);
}
#cookie-popup > div {
  max-width: 1140px;
  margin: auto;
  line-height: 40px;
}

#cookie-popup  p {
  color: black;
  margin-bottom: 0;
  float: left;
}

#cookie-popup a {
  color: #FF8E15;
  text-underline: #f49b00;
  text-decoration-line: underline;
}

.buttons > button {
  background-color: black;
  border: none;
  cursor: pointer;
  color: white;
  height: 40px;
  width: 90px;
  border-radius: 5px;
}

    .connectUs {
        display: flex;
        flex-wrap: wrap;
    }

.connectUs .connect-item {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.3);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-left: 10px;
}

.connectUs .connect-item:hover {
  background: linear-gradient(to bottom, #ffea62, #fc870a);
}

.connectUs .connect-item img {
  width: 26px;
  height: 26px;
  object-fit: contain;
}

#webpack-dev-server-client-overlay {
  display: none !important;
}

.footer1 {
  font-weight: bold;
  font-size: 18px;
  text-align: left;
}

.footer2 {
  text-align: left;
  font-size: 14px;
  margin-top: 20px;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.8);
}

.footer2:hover {
  color: #ff8e15;
}

/*@font-face {*/
/*    font-family: 'MiSans-Regular';*/
/*    src: url('@/assets/font/MiSans-Regular.woff') format('woff');*/
/*    font-display: swap;*/
/*}*/
/*@font-face {*/
/*    font-family: 'MiSans-Semibold';*/
/*    src: url('@/assets/font/MiSans-Semibold.woff') format('woff');*/
/*    font-display: swap;*/
/*}*/
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--default-text-color);
  overflow: auto;
  height: 100%;
}

.components-layout-demo-basic {
  text-align: center;
}

#flashHeader {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC",
    "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
}

.flashFooter {
  /*font-family: MiSans-Regular,serif;*/
}

#flashMain {
  /*font-family: MiSans-Regular,serif;*/
  min-height: calc(100vh - 64px);
}

.components-layout-demo-basic .ant-layout-header,
.components-layout-demo-basic .ant-layout-footer {
  background: #7dbcea;
  color: #fff;
}
</style>
