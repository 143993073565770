// zh.js
export default {
    header: {
        home: 'ホームページ',
        download: 'ダウンロード',
        helpCenter: 'ヘルプ',
        signIn: 'ログイン',
        myAccount: '私のアカウント',
        changePwd: 'パスワードを変更',
        signOut: 'サインアウト',
        vip: '買う',
        blog: 'ブログ',
        promotionAgency: 'プロモーションエージェント',
        yourIP: 'IP: ',
        yourStatus: 'あなたのステータス: ',
        statusProtected: '保護された',
        statusUnprotected: '保護されていない',
        statusDesc: 'IPアドレスを非表示にするには、LightningX VPNを取得して、いずれかのサーバーに接続する必要があります。'
    },
    tagsView: {
        refresh: 'リフレッシュする',
        close: '閉鎖',
        closeOthers: 'その他を閉じる',
        closeAll: 'すべて閉じる'
    },
    settings: {
        title: 'システムレイアウト構成',
        theme: 'テーマカラー',
        tagsView: 'タグビューをオンにする',
        fixedHeader: '固定ヘッダー',
        sidebarLogo: 'サイドバーのロゴ'
    },
    footer: {
        pp: 'プライバシーポリシー',
        ts: '利用規約',
        company: '会社',
        email: '郵便',
        address: '住所',
        download: 'ダウンロード',
        helpCenter: 'ヘルプセンター',
        about: '私たちについて',
        productFaqs: 'よくある問題',
        ios_help: 'iOS FAQS',
        apk_help: 'Android FAQS',
        mac_help: 'macOS FAQS',
        win_help: 'Windows FAQS',
        cooperation: '提携する',
        franchise: '加盟します',
        guarantee:'30日間の返金保証'
    },
    notFound: {
        content: 'アクセスしたい情報は存在しません...',
        toHomePage: 'ホームページに戻る'
    },
    cookiesPolicy: {
        desc: 'このウェブサイトはクッキーを使用しています。詳細については、当社の<a href="/ja/privacyPolicy" target="_blank" class="">&nbsp;プライバシー ポリシー</a><span>をご確認ください。</span>',
        btnAllow: '受け入れる',
        btnDenied: '拒否'
    }
}
