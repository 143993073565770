// es.js
export default {
    header: {
      home: 'Inicio ',
      download: 'Descargar',
      helpCenter: 'Ayuda',
      signIn: 'Acceso',
      myAccount: 'mi cuenta',
      changePwd: 'cambiar la contraseña',
      signOut: 'desconectar',
      vip: 'Precio',
      blog:'Blog',
      promotionAgency:'agente de promoción',
      yourIP: 'Tu dirección Ip: ',
      yourStatus: 'Tu estado: ',
      statusProtected: 'Protegido',
      statusUnprotected: 'Desprotegido',
      statusDesc: 'Para ocultar su dirección IP, necesita obtener LightningX VPN y conectarse a uno de los servidores.'
    },
    tagsView: {
      refresh: 'actualizar',
      close: 'cierre',
      closeOthers: 'Cerrar otro',
      closeAll: 'Cierra todo'
    },
    settings: {
      title: 'Configuración del diseño del sistema',
      theme: 'color del tema',
      tagsView: 'encender Tags-View',
      fixedHeader: 'fijado Header',
      sidebarLogo: 'Barra lateral Logo'
    },
    footer: {
      pp: 'Política de Privacidad',
      ts: 'Términos de servicio',
      company: 'compañía',
      email: 'correo',
      address: 'DIRECCIÓN',
      download: 'Descargar',
      helpCenter: 'Centro de ayuda',
      about: 'Sobre nosotros',
      productFaqs: 'Preguntas frecuentes',
      ios_help:'Preguntas frecuentes sobre iOS',
      apk_help:'Preguntas frecuentes sobre Android',
      mac_help:'Preguntas frecuentes sobre macOS',
      win_help:'Preguntas frecuentes sobre Windows',
      cooperation:'Cooperación',
      franchise:'Conviértete en afiliado',
      guarantee:'30-day money-back guarantee'
    },
    notFound:{
      content:'La información a la que desea acceder no existe...',
      toHomePage:'Volver a la página de inicio'
    },
    cookiesPolicy: {
        desc: 'Este sitio web utiliza cookies. Para obtener más información, consulte nuestra<a href="/es/privacyPolicy" target="_blank" class="">&nbsp;Política de privacidad</a><span>.</span>',
        btnAllow: 'Aceptar',
        btnDenied: 'Rechazar'
    }
}
