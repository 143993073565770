import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import Antd from 'ant-design-vue';
import { Button, message, Layout, Result, Icon, Spin, FormModel, Input, Form, Menu, Row, Col, Divider, List, Steps, Badge, Tabs, Card, Carousel, Comment, Rate, Avatar, Tag, InputNumber, Popconfirm, Descriptions, Table, Dropdown, Checkbox, notification, Drawer, Modal, Collapse, Pagination, Anchor, Select ,Tooltip} from "ant-design-vue";
// import 'ant-design-vue/dist/antd.css';
import '@/assets/js/iconfont'
import '@/assets/css/index.less'
import VueMeta from 'vue-meta';
//国际化
import i18n from './lang'

// firebase start
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// const firebaseConfig = {
//   apiKey: "AIzaSyA6FtlMe0w3uayRCHxOqpGBU4pO62OL9Qg",
//   authDomain: "flashx-vpn-383b6.firebaseapp.com",
//   projectId: "flashx-vpn-383b6",
//   storageBucket: "flashx-vpn-383b6.appspot.com",
//   messagingSenderId: "308470832598",
//   appId: "1:308470832598:web:f41d6617084055d1b4d877",
//   measurementId: "G-2865G8EXG3"
// };
// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
// Vue.prototype.$analytics = analytics

Vue.config.productionTip = false
Vue.prototype.$message = message
Vue.prototype.$notification = notification
message.config({
  top: '200px'
})

if (process.env.NODE_ENV === 'production') {
  //发布环境
  let domain = window.location.hostname;
  domain = domain.replace(/^www\./, '').replace(/^link\./,'');
  //main 分支 生产 pro
  Vue.prototype.$basedownloadUrl = `https://download.${domain}`;
  Vue.prototype.$baseReturnUrl = `https://${domain}/`;
  Vue.prototype.$baseApiUrl = `https://api.${domain}`;
} else {
  //本地环境
  Vue.prototype.$basedownloadUrl = 'https://download.shanlianvpn.info';
  Vue.prototype.$baseReturnUrl = 'http://' + window.location.hostname.replace(/^www\./, '') + ":" + window.location.port + '/';
  Vue.prototype.$baseApiUrl = 'https://7hzz2koj4g.execute-api.ap-east-1.amazonaws.com/dev';
}

// Vue.use(Antd)
Vue.use(Button)
Vue.use(Layout)
Vue.use(Result)
Vue.use(Icon)
Vue.use(Spin)
Vue.use(FormModel)
Vue.use(Input)
Vue.use(Menu)
Vue.use(Row)
Vue.use(Col)
Vue.use(Divider)
Vue.use(List)
Vue.use(Steps)
Vue.use(Badge)
Vue.use(Tabs)
Vue.use(Card)
Vue.use(Carousel)
Vue.use(Comment)
Vue.use(Rate)
Vue.use(Avatar)
Vue.use(Tag)
Vue.use(InputNumber)
Vue.use(Popconfirm)
Vue.use(Descriptions)
Vue.use(Table)
Vue.use(Dropdown)
Vue.use(Checkbox)
Vue.use(Drawer)
Vue.use(Modal)
Vue.use(Collapse)
Vue.use(Pagination)
Vue.use(Anchor)
Vue.use(Select)
Vue.use(Form)
Vue.use(Tooltip)
Vue.use(VueMeta);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
