import request from '@/utils/request';

export function getAffiliateInfoApi() {
    return request({
        url: '/app/customer/getAffiliateInfo',
        method: 'get',
    })
}
//获取佣金列表订单信息
export function getAffiliateOrdersApi() {
    return request({
        url: '/flash/order/listAffiliateOrders?pageNum=1&pageSize=100',
        method: 'get',
    })
}
//获取佣金列表订单信息
export function getListByUserApi() {
    return request({
        url: '/system/withdrawal/listByUser',
        method: 'get',
    })
}