// de.js
export default {
    header: {
        home: 'Startseite',
        download: 'Herunterladen',
        helpCenter: 'Hilfezentrum',
        signIn: 'Anmelden',
        myAccount: 'Mein Konto',
        changePwd: 'Passwort ändern',
        signOut: 'Abmelden',
        vip: 'Preis',
        blog: 'Blog',
        promotionAgency: 'Werbeagentur',
        yourIP: 'Deine IP: ',
        yourStatus: 'Dein Status: ',
        statusProtected: 'Geschützt',
        statusUnprotected: 'Ungeschützt',
        statusDesc: 'Um deine IP-Adresse zu verbergen, benötigst du LightningX VPN und musst dich mit einem der Server verbinden.'
    },
    tagsView: {
        refresh: 'Aktualisieren',
        close: 'Schließen',
        closeOthers: 'Andere schließen',
        closeAll: 'Alle schließen'
    },
    settings: {
        title: 'Seitenstil-Einstellung',
        theme: 'Themenfarbe',
        tagsView: 'Tags-Ansicht öffnen',
        fixedHeader: 'Feste Kopfzeile',
        sidebarLogo: 'Seitenleistenlogo'
    },
    footer: {
        pp: 'Datenschutz-Bestimmungen',
        ts: 'Nutzungsbedingungen',
        company: 'Firmenname',
        email: 'E-Mail',
        address: 'Adresse',
        download: 'Herunterladen',
        helpCenter: 'Hilfezentrum',
        about: 'Über uns',
        productFaqs: 'FAQs',
        ios_help: 'iOS-FAQs',
        apk_help: 'Android-FAQs',
        mac_help: 'macOS-FAQs',
        win_help: 'Windows-FAQs',
        cooperation: 'Zusammenarbeit',
        franchise: 'Partner werden',
        guarantee: '30-tägige Geld-zurück-Garantie'
    },
    notFound: {
        content: 'Die von Ihnen gesuchten Informationen existieren nicht...',
        toHomePage: 'Zurück zur Startseite'
    },
    cookiesPolicy: {
        desc: 'Diese Website verwendet Cookies, um die Benutzererfahrung zu verbessern. Weitere Informationen finden Sie in unserer <a href="/privacyPolicy" target="_blank" class="">Datenschutzerklärung</a><span>.</span>',
        btnAllow: 'Akzeptieren',
        btnDenied: 'Ablehnen'
    }

}
